import axios from "axios";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GetToken } from "../Authcontext";
import { useHistory } from "react-router-dom";

export const actionTypes = {
  SET_TOKEN: "SET_TOKEN",
  SET_LOGIN: "SET_LOGIN",
  LOGOUT_ACTION: "LOGOUT_ACTION",
  GET_USER: "GET_USER",
  SET_CHANGE_PASSWORD: "SET_CHANGE_PASSWORD",
  LOADING_TOGGLE_ACTION: "LOADING_TOGGLE_ACTION",
  SET_USER_NULL: "SET_USER_NULL",
  GET_FORGOT_PASSWORD: "GET_FORGOT_PASSWORD",
};

/*--------------------------------set preloader------------------------ */
export const setPreloaderTrueAuth = () => {
  return {
    type: actionTypes.LOADING_TOGGLE_ACTION,
    Preloader: true,
  };
};
/*--------------------------------Reset user------------------------ */
export const setUserNull = () => {
  return {
    type: actionTypes.SET_USER_NULL,
    user: "",
  };
};

/*--------------------------login token---------------------*/
export const settoken = () => {
  return async (dispatch) => {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/api/User/Login_New`, "")
      .then((response) => {
        // console.log(response.data)
        if (response.data.StatusCode === 200) {
          dispatch({
            type: actionTypes.SET_TOKEN,
            token: response.data,
            Preloader: false,
          });
        } else {
          dispatch({
            type: actionTypes.LOADING_TOGGLE_ACTION,
            Preloader: false,
          });
        }
      })
      .catch((err) => console.log(err));
  };
};
/*--------------------------login token---------------------*/

/*--------------------------login ---------------------*/

export const setlogin = (RegNo, password, history) => {
  const token = GetToken();

  const Data = { RegNo: RegNo, Pwd: password };

  return async (dispatch) => {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/api/User/Login_New`, Data, {
        headers: {
          AccessToken: token,
        },
      })
      .then((response) => {
        if (response.data.StatusCode === 200) {
          dispatch({
            type: actionTypes.SET_LOGIN,
            user: response.data,
            Preloader: false,
          });

          history.push("/patient-list");
        } else {
          dispatch({
            type: actionTypes.LOADING_TOGGLE_ACTION,
            Preloader: false,
          });
          toast.error(
            <>
              <div className="text-danger fs-20">
                {response.data.StatusMessage}
              </div>
            </>
          );
        }
      })
      .catch((err) => history.push("/page-error-500"));
  };
};

export function Logout(his) {
 
  const cookies = new Cookies();

  localStorage.removeItem("user");
  localStorage.clear();
  cookies.remove("token");
  his.push("/login");
  window.location.reload();
  return {
    type: actionTypes.LOGOUT_ACTION,
  };
}

/*--------------------------------------------change password--------------------------------------*/
export const ChangePassword = (Id, CurrentPass, newPass, history) => {
  const token = GetToken();

  const Data = {
    UserId: Id,
    CurrentPwd: CurrentPass,
    NewPwd: newPass,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/User/ChangePassword_New`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        if (response.data.StatusCode === 200) {
          toast.success(
            <>
              <span>Password Change Successfully!!</span>
            </>
          );
          setTimeout(() => {
            dispatch(Logout());
          }, 10);
        } else {
          toast.error(
            <>
              <span>{response.data.StatusMessage}</span>
            </>
          );
        }
      })
      .catch((err) => console.log(err));
  };
};

/*--------------------------Forgot Password ---------------------*/

export const ForgotPassword = (RegNo) => {
  const token = GetToken();

  const Data = { RegNo: RegNo };

  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/user/ForgotPassword_New`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: actionTypes.GET_FORGOT_PASSWORD,
          forgotpassword: response.data,
          Preloader: false,
        });
        if (response.data.StatusCode === 200) {
          toast.success(
            <>
              <div className="text-success">
                {response.data.StatusMessage}
              </div>
            </>
          );
        }
        if (response.data.StatusCode === 201) {
          toast.error(
            <>
              <div className="text-error">
                {response.data.StatusMessage}
              </div>
            </>
          );
        }
      })
      .catch((err) => {
        toast.error(
          <>
            <span>An error has occurred.</span>
          </>
        );
      });
  };
};

export const reset = (RESET) => {
  return {
    type: RESET,
  };
};
