export const actionTypes = {
  GET_CHIF_COMPLAINTS_LIST: "GET_CHIF_COMPLAINTS_LIST",
  GET_CHIF_COMPLAINTS: "GET_CHIF_COMPLAINTS",
  GET_ASSOCIATED_COMPLAINTS_LIST: "GET_ASSOCIATED_COMPLAINTS_LIST",
  GET_ASSOCIATED_COMPLAINTS: "GET_ASSOCIATED_COMPLAINTS",
  GET_PERSONAL_ATTRIBUTES_LIST: "GET_PERSONAL_ATTRIBUTES_LIST",
  GET_PAST_MEDICAL_HISTORY_LIST: "GET_PAST_MEDICAL_HISTORY_LIST",
  GET_EXAMINATION_LIST: "GET_EXAMINATION_LIST",
  GET_MEDICATION_LIST: "GET_MEDICATION_LIST",
  GET_VACCINATION_HISTORY_LIST: "GET_VACCINATION_HISTORY_LIST",
  GET_ALLERGIES_LIST: "GET_ALLERGIES_LIST",
  GET_FAMILY_HISTORY_LIST: "GET_FAMILY_HISTORY_LIST",
  GET_LIFE_SITUATION_HISTORY_LIST: "GET_LIFE_SITUATION_HISTORY_LIST",
  GET_CHILDHOOD_HISTORY_LIST: "GET_CHILDHOOD_HISTORY_LIST",
  GET_THERMAL_HISTORY_LIST: "GET_THERMAL_HISTORY_LIST",
  SET_PRE_LOADER: "SET_PRE_LOADER",
  GET_MEDICINE_SYSTEM_LIST: "GET_MEDICINE_SYSTEM_LIST",
  GET_VACCINATIONNAME_LIST: "GET_VACCINATIONNAME_LIST",
  GET_VACCINATIONSCHEDULE_LIST: "GET_VACCINATIONSCHEDULE_LIST",
  GET_VACCINATIONMETHOD_LIST: "GET_VACCINATIONMETHOD_LIST",
  GET_ALLERGICTO_LIST: "GET_ALLERGICTO_LIST",
  GET_FAMILYRELATIONSHIP_LIST: "GET_FAMILYRELATIONSHIP_LIST",
  GET_DISEASECONDITION_LIST: "GET_DISEASECONDITION_LIST",
  GET_ORGAN_LIST: "GET_ORGAN_LIST",
  GET_INVESTIGATIONREPORTTYPE_LIST: "GET_INVESTIGATIONREPORTTYPE_LIST",
  GET_CHILDHOODHABIT_LIST: "GET_CHILDHOODHABIT_LIST",
  GET_STUDYTRACKRECORD_LIST: "GET_STUDYTRACKRECORD_LIST",
  GET_INVESTIGATIONREPORT_LIST: "GET_INVESTIGATIONREPORT_LIST",
  GET_VIEW_INVEST_LIST: "GET_VIEW_INVEST_LIST",
  GET_MENSTRUAL_HISTORY_LIST: "GET_MENSTRUAL_HISTORY_LIST",
  GET_MENSTRUAL_HISTORY_BY_ID: "GET_MENSTRUAL_HISTORY_BY_ID",
  GET_OBSTETRIC_HISTORY_LIST: "GET_OBSTETRIC_HISTORY_LIST",
  GET_SURGICAL_HISTORY_LIST: "GET_SURGICAL_HISTORY_LIST",
  GET_VIEW_SURGICAL_HISTORY_BYID: "GET_VIEW_SURGICAL_HISTORY_BYID",
  GET_VIEW_THERMAL_HISTORY_LIST: "GET_VIEW_THERMAL_HISTORY_LIST",
  GET_GERITRIC_HISTORY_LIST: "GET_GERITRIC_HISTORY_LIST",
  GET_VIEW_GERIATRIC_LIST: "GET_VIEW_GERIATRIC_LIST",
  GET_THERMALS_DETAILS_QUATIONS_LIST: "GET_THERMALS_DETAILS_QUATIONS_LIST",
  GET_GERIATIC_QUATIONS_LIST: "GET_GERIATIC_QUATIONS_LIST",
  GET_EDIT_THERMALS_DETAILS_QUATIONS_LIST:
    "GET_EDIT_THERMALS_DETAILS_QUATIONS_LIST",
  GET_FORM_CASERECORD_ID: "GET_FORM_CASERECORD_ID",
  GET_ALTERNATIVE_LIST: "GET_ALTERNATIVE_LIST",
  GET_ALTERNATIVE_CHECKBOX: "GET_ALTERNATIVE_CHECKBOX",
  GET_EDIT_ALTERNATIVE_LIST: "GET_EDIT_ALTERNATIVE_LIST",
  RESET_EDIT_ALTERNATIVE_LIST: "RESET_EDIT_ALTERNATIVE_LIST",
  GET_MEDICATIONFREQUANCY_LIST : 'GET_MEDICATIONFREQUANCY_LIST',
  GET_MEDICINE_NAME : 'GET_MEDICINE_NAME',
  GET_MEDICINE_CONDITION : 'GET_MEDICINE_CONDITION',
  GET_QUICK_CASE_LIST : 'GET_QUICK_CASE_LIST'
};

const INITIAL_STATE = {
  ChiefComplaintslist: [],
  ChiefComplaints: [],
  AssociatedComplaintslist: [],
  AssociatedComplaints: [],
  PersonalAttributeslist: [],
  PastMedicalHistorylist: [],
  Examinationlist: [],
  Medicationlist: [],
  VaccinationHistorylist: [],
  Allergieslist: [],
  FamilyHistorylist: [],
  LifeSituationHistorylist: [],
  ChildhoodHistorylist: [],
  ThermalHistoryList: [],
  ViewThermalHistoryList: [],
  MedicineSystemList: [],
  VaccinationNameList: [],
  VaccinationScheduleList: [],
  VaccinationMethodList: [],
  AllergicTo: [],
  FamilyRelationshiplist: [],
  DiseaseConditionlist: [],
  Organlist: [],
  InvestigationReportType: [],
  ChildhoodHabitList: [],
  StudyTrackRecordList: [],
  Preloader: false,
  InvestigationList: [],
  ViewInvestReportList: [],
  MentrualHistroyList: [],
  ViewMentrualHistroyList: [],
  ObstetricHistoryList: [],
  SurgicalHistoryList: [],
  ViewSurgicalHistoryData: [],
  GetriatricHistoryList: [],
  ViewGetriatricHistoryData: [],
  GetriatricQuestions: [],
  EditThermalsDetailsQuations: [],
  getCaseRecordId: null,
  getAlternativelist: [],
  EditAlternativelist: [],
  getAlternativecheckbox: [],
  MedicationFrequencyList : [],
  MedicineNameList : [],
  MedicineConditionList : [],
  CaseList : []
};

const CaseRecordReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // -----------------------------------GET_CHIF_COMPLAINTS_LIST------------------------------------
    case actionTypes.GET_CHIF_COMPLAINTS_LIST:
      return {
        ...state,
        ChiefComplaintslist: action.ChiefComplaintslist,
        Preloader: action.Preloader,
      };
    // -----------------------------------GET_CHIF_COMPLAINTS------------------------------------
    case actionTypes.GET_CHIF_COMPLAINTS:
      return {
        ...state,
        ChiefComplaints: action.ChiefComplaints,
      };
    case actionTypes.GET_ALTERNATIVE_LIST:
      return {
        ...state,
        getAlternativelist: action.getAlternativelist,
      };
    case actionTypes.GET_ALTERNATIVE_CHECKBOX:
      return {
        ...state,
        getAlternativecheckbox: action.getAlternativecheckbox,
      };
    case actionTypes.GET_EDIT_ALTERNATIVE_LIST:
      return {
        ...state,
        EditAlternativelist: action.EditAlternativelist,
      };
    case actionTypes.GET_MEDICATIONFREQUANCY_LIST:
      return {
        ...state,
        MedicationFrequencyList: action.MedicationFrequencyList,
      };
    case actionTypes.GET_MEDICINE_NAME:
      return {
        ...state,
        MedicineNameList: action.MedicineNameList,
      };
    case actionTypes.RESET_EDIT_ALTERNATIVE_LIST:
      return {
        ...state,
        EditAlternativelist: INITIAL_STATE.EditAlternativelist,
      };
    // -----------------------------------GET_ASSOCIATED_COMPLAINTS_LIST------------------------------------
    case actionTypes.GET_ASSOCIATED_COMPLAINTS_LIST:
      return {
        ...state,
        AssociatedComplaintslist: action.AssociatedComplaintslist,
        Preloader: action.Preloader,
      };
    // -----------------------------------GET_ASSOCIATED_COMPLAINTS------------------------------------
    case actionTypes.GET_ASSOCIATED_COMPLAINTS:
      return {
        ...state,
        AssociatedComplaints: action.AssociatedComplaints,
      };
    case actionTypes.GET_MEDICINE_CONDITION:
      return {
        ...state,
        MedicineConditionList: action.MedicineConditionList,
      };
    case actionTypes.GET_QUICK_CASE_LIST:
      return {
        ...state,
        CaseList: action.CaseList,
      };
    // -----------------------------------PersonalAttributeslist------------------------------------
    case actionTypes.GET_PERSONAL_ATTRIBUTES_LIST:
      return {
        ...state,
        PersonalAttributeslist: action.PersonalAttributeslist,
        Preloader: action.Preloader,
      };
    // -----------------------------------GET_PAST_MEDICAL_HISTORY_LIST------------------------------------
    case actionTypes.GET_PAST_MEDICAL_HISTORY_LIST:
      return {
        ...state,
        PastMedicalHistorylist: action.PastMedicalHistorylist,
        Preloader: action.Preloader,
      };
    // -----------------------------------GET_EXAMINATION_LIST------------------------------------
    case actionTypes.GET_EXAMINATION_LIST:
      return {
        ...state,
        Examinationlist: action.Examinationlist,
        Preloader: action.Preloader,
      };
    // -----------------------------------GET_MEDICATION_LIST------------------------------------
    case actionTypes.GET_MEDICATION_LIST:
      return {
        ...state,
        Medicationlist: action.Medicationlist,
        Preloader: action.Preloader,
      };
    // -----------------------------------GET_VACCINATION_HISTORY_LIST------------------------------------
    case actionTypes.GET_VACCINATION_HISTORY_LIST:
      return {
        ...state,
        VaccinationHistorylist: action.VaccinationHistorylist,
        Preloader: action.Preloader,
      };
    // -----------------------------------GET_ALLERGIES_LIST------------------------------------
    case actionTypes.GET_ALLERGIES_LIST:
      return {
        ...state,
        Allergieslist: action.Allergieslist,
        Preloader: action.Preloader,
      };
    // -----------------------------------GET_FAMILY_HISTORY_LIST------------------------------------
    case actionTypes.GET_FAMILY_HISTORY_LIST:
      return {
        ...state,
        FamilyHistorylist: action.FamilyHistorylist,
        Preloader: action.Preloader,
      };
    // -----------------------------------GET_LIFE_SITUATION_HISTORY_LIST------------------------------------
    case actionTypes.GET_LIFE_SITUATION_HISTORY_LIST:
      return {
        ...state,
        LifeSituationHistorylist: action.LifeSituationHistorylist,
        Preloader: action.Preloader,
      };
    // -----------------------------------GET_CHILDHOOD_HISTORY_LIST------------------------------------
    case actionTypes.GET_CHILDHOOD_HISTORY_LIST:
      return {
        ...state,
        ChildhoodHistorylist: action.ChildhoodHistorylist,
        Preloader: action.Preloader,
      };
    // -----------------------------------GET_THERMAL_HISTORY_LIST------------------------------------
    case actionTypes.GET_THERMAL_HISTORY_LIST:
      return {
        ...state,
        ThermalHistoryList: action.ThermalHistoryList,
        Preloader: action.Preloader,
      };
    // -----------------------------------GET_VIEW_THERMAL_HISTORY_LIST------------------------------------
    case actionTypes.GET_VIEW_THERMAL_HISTORY_LIST:
      return {
        ...state,
        ViewThermalHistoryList: action.ViewThermalHistoryList,
        Preloader: action.Preloader,
      };
    // -----------------------------------SET_PRE_LOADER------------------------------------
    case actionTypes.SET_PRE_LOADER:
      return {
        ...state,
        Preloader: action.Preloader,
      };
    // -----------------------------------GET_MEDICINE_SYSTEM_LIST------------------------------------
    case actionTypes.GET_MEDICINE_SYSTEM_LIST:
      return {
        ...state,
        MedicineSystemList: action.MedicineSystemList,
      };
    // -----------------------------------GET_VACCINATIONNAME_LIST------------------------------------
    case actionTypes.GET_VACCINATIONNAME_LIST:
      return {
        ...state,
        VaccinationNameList: action.VaccinationNameList,
        Preloader: action.Preloader,
      };
    // -----------------------------------GET_VACCINATIONSCHEDULE_LIST------------------------------------
    case actionTypes.GET_VACCINATIONSCHEDULE_LIST:
      return {
        ...state,
        VaccinationScheduleList: action.VaccinationScheduleList,
      };
    // -----------------------------------GET_VACCINATIONMETHOD_LIST------------------------------------
    case actionTypes.GET_VACCINATIONMETHOD_LIST:
      return {
        ...state,
        VaccinationMethodList: action.VaccinationMethodList,
      };
    // -----------------------------------GET_ALLERGICTO_LIST------------------------------------
    case actionTypes.GET_ALLERGICTO_LIST:
      return {
        ...state,
        AllergicTo: action.AllergicTo,
      };
    // -----------------------------------GET_FAMILYRELATIONSHIP_LIST------------------------------------
    case actionTypes.GET_FAMILYRELATIONSHIP_LIST:
      return {
        ...state,
        FamilyRelationshiplist: action.FamilyRelationshiplist,
      };
    // -----------------------------------GET_DISEASECONDITION_LIST------------------------------------
    case actionTypes.GET_DISEASECONDITION_LIST:
      return {
        ...state,
        DiseaseConditionlist: action.DiseaseConditionlist,
      };
    // -----------------------------------GET_ORGAN_LIST------------------------------------
    case actionTypes.GET_ORGAN_LIST:
      return {
        ...state,
        Organlist: action.Organlist,
      };
    // -----------------------------------GET_INVESTIGATIONREPORTTYPE_LIST------------------------------------
    case actionTypes.GET_INVESTIGATIONREPORTTYPE_LIST:
      return {
        ...state,
        InvestigationReportType: action.InvestigationReportType,
      };
    // -----------------------------------GET_CHILDHOODHABIT_LIST------------------------------------
    case actionTypes.GET_CHILDHOODHABIT_LIST:
      return {
        ...state,
        ChildhoodHabitList: action.ChildhoodHabitList,
      };
    // -----------------------------------GET_STUDYTRACKRECORD_LIST------------------------------------
    case actionTypes.GET_STUDYTRACKRECORD_LIST:
      return {
        ...state,
        StudyTrackRecordList: action.StudyTrackRecordList,
      };
    // -----------------------------------GET_INVESTIGATIONREPORT_LIST------------------------------------
    case actionTypes.GET_INVESTIGATIONREPORT_LIST:
      return {
        ...state,
        InvestigationList: action.InvestigationList,
        Preloader: action.Preloader,
      };
    // -----------------------------------GET_VIEW_INVEST_LIST------------------------------------
    case actionTypes.GET_VIEW_INVEST_LIST:
      return {
        ...state,
        ViewInvestReportList: action.ViewInvestReportList,
        Preloader: action.Preloader,
      };
    // -----------------------------------GET_MENSTRUAL_HISTORY_LIST------------------------------------
    case actionTypes.GET_MENSTRUAL_HISTORY_LIST:
      return {
        ...state,
        MentrualHistroyList: action.MentrualHistroyList,
        Preloader: action.Preloader,
      };
    // -----------------------------------GET_MENSTRUAL_HISTORY_BY_ID------------------------------------
    case actionTypes.GET_MENSTRUAL_HISTORY_BY_ID:
      return {
        ...state,
        ViewMentrualHistroyList: action.ViewMentrualHistroyList,
        Preloader: action.Preloader,
      };
    // -----------------------------------GET_OBSTETRIC_HISTORY_LIST------------------------------------
    case actionTypes.GET_OBSTETRIC_HISTORY_LIST:
      return {
        ...state,
        ObstetricHistoryList: action.ObstetricHistoryList,
        Preloader: action.Preloader,
      };
    // -----------------------------------GET_GERITRIC_HISTORY_LIST------------------------------------
    case actionTypes.GET_GERITRIC_HISTORY_LIST:
      return {
        ...state,
        GetriatricHistoryList: action.GetriatricHistoryList,
        Preloader: action.Preloader,
      };
    // -----------------------------------GET_SURGICAL_HISTORY_LIST------------------------------------
    case actionTypes.GET_SURGICAL_HISTORY_LIST:
      return {
        ...state,
        SurgicalHistoryList: action.SurgicalHistoryList,
        Preloader: action.Preloader,
      };
    // -----------------------------------GET_VIEW_SURGICAL_HISTORY_BYID------------------------------------
    case actionTypes.GET_VIEW_SURGICAL_HISTORY_BYID:
      return {
        ...state,
        ViewSurgicalHistoryData: action.ViewSurgicalHistoryData,
        Preloader: action.Preloader,
      };
    // -----------------------------------GET_VIEW_GERIATRIC_LIST-----------------------------------
    case actionTypes.GET_VIEW_GERIATRIC_LIST:
      return {
        ...state,
        ViewGetriatricHistoryData: action.ViewGetriatricHistoryData,
        Preloader: action.Preloader,
      };
    // -----------------------------------GET_THERMALS_DETAILS_QUATIONS_LIST-----------------------------------
    case actionTypes.GET_THERMALS_DETAILS_QUATIONS_LIST:
      return {
        ...state,
        ThermalsDetailsQuations: action.ThermalsDetailsQuations,
        Preloader: action.Preloader,
      };
    // -----------------------------------GET_GERIATIC_QUATIONS_LIST-----------------------------------
    case actionTypes.GET_GERIATIC_QUATIONS_LIST:
      return {
        ...state,
        GetriatricQuestions: action.GetriatricQuestions,
        Preloader: action.Preloader,
      };
    // -----------------------------------GET_EDIT_THERMALS_DETAILS_QUATIONS_LIST-----------------------------------
    case actionTypes.GET_EDIT_THERMALS_DETAILS_QUATIONS_LIST:
      return {
        ...state,
        EditThermalsDetailsQuations: action.EditThermalsDetailsQuations,
        Preloader: action.Preloader,
      };

    // -----------------------------------GET_MESSAGE_TIME_LINE_LIST_PATIENT------------------------------------
    case actionTypes.GET_FORM_CASERECORD_ID:
      return {
        ...state,
        // getCaseRecordId: action.getCaseRecordId,
        getCaseRecordId: action.getCaseRecordId?.Data?.CaseRecordId,
      };

    default:
      return state;
  }
};

export default CaseRecordReducer;
