export const actionTypes = {
  GET_SUPPORT_PATIENT_LIST: "GET_SUPPORT_PATIENT_LIST",
  GET_SUPPORT_TYPE_LIST: "GET_SUPPORT_TYPE_LIST",
  SET_PRE_LOADER: "SET_PRE_LOADER",
  SUPPORT_REQUIRED_SAVE: "SUPPORT_REQUIRED_SAVE",
  RESET_SUPPORT_SAVE: "RESET_SUPPORT_SAVE",
};

const INITIAL_STATE = {
  supportPatientList: [],
  GetSupportTypeList: [],
  PreloaderS: false,
  GetSupportsave: [],
  ResetSupportsave: [],
};

const SupportReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // -----------------------------------SET_PRE_LOADER------------------------------------
    case actionTypes.SET_PRE_LOADER:
      return {
        ...state,
        PreloaderS: action.PreloaderS,
      };
    // -----------------------------------GetPatientList------------------------------------
    case actionTypes.GET_SUPPORT_PATIENT_LIST:
      localStorage.setItem(
        "supportPatientList",
        JSON.stringify(action.supportPatientList.Data)
      );
      return {
        ...state,
        supportPatientList: action.supportPatientList,
      };
    // -----------------------------------GetSupportTypeList------------------------------------
    case actionTypes.GET_SUPPORT_TYPE_LIST:
      localStorage.setItem(
        "GetSupportTypeList",
        JSON.stringify(action.GetSupportTypeList.Data)
      );
      return {
        ...state,
        GetSupportTypeList: action.GetSupportTypeList,
      };
    case actionTypes.SUPPORT_REQUIRED_SAVE:
      return {
        ...state,
        GetSupportsave: action.GetSupportsave,
        PreloaderS: action.PreloaderS,
      };
    case actionTypes.RESET_SUPPORT_SAVE:
      return {
        ...state,
        GetSupportsave: INITIAL_STATE.GetSupportsave,
      };

    default:
      return state;
  }
};

export default SupportReducer;
