import React, { useContext } from "react";

/// React router dom
import {  Switch, Route,  } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";

import Setting from "./layouts/Setting";
import { ThemeContext } from "../context/ThemeContext";
import Home from "../demo_webpage/Home";

import Payumoney from "./pages/patient/HealthPlan/Payumoney";
const PayumoneyPage = () => {
  // const [user,setuser] = useState('admin')
  const { toggle,menuToggle , setToggleFalse ,openMenuToggle ,closeMenuToggle} = useContext(ThemeContext);

  const routes = [

    
    { url: "/home/:doctorId", component: Home , permission: ['admin','user']},

    /// pages
    
    { url: "/page-error-404", component: Error404 , permission: ['admin','user']},
   
  ];
  let path = window.location?.pathname;
  // path = path.split("/");
  // path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  
  const menuClick = () => {
    if(window.innerWidth <= 767){
      if(toggle) {
      setToggleFalse(); 
      setTimeout(() => {
       window.dispatchEvent(new Event('resize')) 
      }, 1);
      
   } 
    }
   

  }

  return (

    
            <Switch>
            <Route  path="/payumoney"  component={Payumoney} />
            </Switch>
  )
};
export default PayumoneyPage;