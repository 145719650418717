import { lazy, Suspense } from "react";

/// Components
// import Index from "./jsx";
import { connect, useDispatch } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
// action
import { isAuthenticated } from "./store/selectors/AuthSelectors";
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import ProLoaderShow from "./jsx/components/ProLoaderShow";
import Error500 from "./jsx/pages/Error500";
import Fullindex from "./jsx/fullindex";
import "./scss/switcher.scss";
import PaytmPage from "./jsx/PaytmPage";
import PayumoneyPage from "./jsx/PayumoneyPage";

const SignUp = lazy(() => import("./jsx/pages/Registration"));
const ForgotPassword = lazy(() => import("./jsx/pages/ForgotPassword"));
const ResetPasswordPage = lazy(() => import("./jsx/pages/ResetPassword"));
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 5);
  });
});
const Index = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx")), 1);
  });
});

function App(props) {
  const dispatch = useDispatch();
  let routes = (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/page-error-500" component={Error500} />
      <Route path="/page-register" component={SignUp} />
      <Route path="/page-forgot-password" component={ForgotPassword} />
      <Route path="/resetpassword" component={ResetPasswordPage} />
    </Switch>
  );
  if (
    props.isAuthenticated &&
    window.location.href.indexOf("preview") === -1 &&
    window.location.href.indexOf("paytm") === -1 &&
    window.location.href.indexOf("payumoney") === -1
  ) {
    return (
      <>
        <Suspense fallback={<ProLoaderShow />}>
          <Index />
        </Suspense>
      </>
    );
  } else if (window.location.href.indexOf("preview") > -1) {
    return <Fullindex />;
  } else if (window.location.href.indexOf("paytm") > -1) {
    return <PaytmPage />;
  } else if (window.location.href.indexOf("payumoney") > -1) {
    return <PayumoneyPage />;
  } else {
    return (
      <div className="vh-100">
        <Suspense fallback={<ProLoaderShow />}>{routes}</Suspense>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(App));

