export const actionTypes = {
  SET_COUNTRY: "SET_COUNTRY",
  SET_DISEASE: "SET_DISEASE",
  CREATE_PATIENT: "CREATE_PATIENT",
  SET_PRE_LOADER: "SET_PRE_LOADER",
  GET_CITY_LIST: "GET_CITY_LIST",
  GET_DISEASE_DETAIL: "GET_DISEASE_DETAIL",
  GET_QUICK_DISEASE : 'GET_QUICK_DISEASE'
};

const INITIAL_STATE = {
  country: [],
  disease: [],
  createpatient: [],
  PreloaderCC: false,
  citylist: [],
  diseaseDetail: [],
  quickdisease : []
};

const CreateContactReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // -----------------------------------set country------------------------------------
    case actionTypes.SET_COUNTRY:
      localStorage.setItem("country", JSON.stringify(action.country.Data));
      return {
        ...state,
        country: action.country,
      };
    case actionTypes.GET_CITY_LIST:
      return {
        ...state,
        citylist: action.citylist,
      };

    // -----------------------------------set DISEASE------------------------------------
    case actionTypes.SET_DISEASE:
      localStorage.setItem("disease", JSON.stringify(action.disease.Data));
      return {
        ...state,
        disease: action.disease,
      };
    //----------------------------------create patient---------------------------------
    case actionTypes.CREATE_PATIENT:
      return {
        ...state,
        createpatient: action.createpatient,
        PreloaderCC: action.PreloaderCC,
      };
    case actionTypes.GET_DISEASE_DETAIL:
      return {
        ...state,
        diseaseDetail: action.diseaseDetail,
      };
    case actionTypes.GET_QUICK_DISEASE:
      return {
        ...state,
        quickdisease: action.quickdisease,
      };
    // -----------------------------------SET_PRE_LOADER------------------------------------
    case actionTypes.SET_PRE_LOADER:
      return {
        ...state,
        PreloaderCC: action.PreloaderCC,
      };

    default:
      return state;
  }
};

export default CreateContactReducer;
