export const actionTypes = {
  SET_PROFILE: "SET_PROFILE",
  GET_BLOOD_GROUP_LIST: "GET_BLOOD_GROUP_LIST",
  GET_PROFESSION_LIST: "GET_PROFESSION_LIST",
  GET_MARITAL_STATUS_LIST: "GET_MARITAL_STATUS_LIST",
  SET_PRE_LOADER: "SET_PRE_LOADER",
  SET_RESET_PASSWORD : 'SET_RESET_PASSWORD',
  CHECK_VALID_LINK : 'CHECK_VALID_LINK'
};

const INITIAL_STATE = {
  profile: [],
  BloodGroupList: [],
  ProfessionList: [],
  maritalStatusList: [],
  resetPassword : [],
  validlink : [],
  Preloader: false,
};

const UserProfileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // -----------------------------------SET_PRE_LOADER------------------------------------
    case actionTypes.SET_PRE_LOADER:
      return {
        ...state,
        Preloader: action.Preloader,
      };
    // -----------------------------------SET PROFILE------------------------------------
    case actionTypes.SET_PROFILE:
      localStorage.setItem("profile", JSON.stringify(action.profile.Data));
      return {
        ...state,
        profile: action.profile.Data,
        Preloader: false,
      };
    // -----------------------------------BloodGroupList------------------------------------
    case actionTypes.GET_BLOOD_GROUP_LIST:
      localStorage.setItem(
        "BloodGroupList",
        JSON.stringify(action.BloodGroupList.Data)
      );
      return {
        ...state,
        BloodGroupList: action.BloodGroupList,
      };
    // -----------------------------------ProfessionList------------------------------------
    case actionTypes.GET_PROFESSION_LIST:
      return {
        ...state,
        ProfessionList: action.ProfessionList,
      };
    // -----------------------------------GET_MARITAL_STATUS_LIST------------------------------------
    case actionTypes.GET_MARITAL_STATUS_LIST:
      return {
        ...state,
        maritalStatusList: action.maritalStatusList,
      };
    case actionTypes.SET_RESET_PASSWORD:
      return {
        ...state,
        resetPassword: action.resetPassword,
      };
    case actionTypes.CHECK_VALID_LINK:
      return {
        ...state,
        validlink: action.validlink,
        Preloader: action.Preloader,
      };

    default:
      return state;
  }
};

export default UserProfileReducer;
