import React from "react";
import { connect } from "react-redux";
const Paytm = ({ PaymentPlanConfirm }) => {
  const data = PaymentPlanConfirm;
  return (
    <div className="w-100" style={{ height: "100vh" }}>
      <iframe srcdoc={data} height="100%" width="100%"></iframe>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    PaymentPlanConfirm: state.myhealthPlan.PaymentPlanConfirm,
    PreloaderHP: state.myhealthPlan.PreloaderHP,
  };
};
export default connect(mapStateToProps)(Paytm);
