import React, { useEffect, useState } from 'react'
import Header from './Header'
import DoctorBanner from './DoctorBanner'
import DoctorInfo from './DoctorInfo'
import DoctorCard from './DoctorCard'
import Doctorvideo from './Doctorvideo'
import ContactUs from './ContactUs'
import Footer from './Footer';
import { connect, useDispatch } from "react-redux";
import ProLoaderShow from "../jsx/components/ProLoaderShow";
import { GetdoctorDetailPreviewPage } from '../store/actions/WebConfigActions'
import { useParams } from 'react-router-dom'
import "../scss/switcher.scss"

const Home = ({ webConfigPreloader , PreviewData }) => {
  const { doctorId } = useParams()
  const [DoctorData, setDoctorData] = useState();
  const [colortheme,setcolortheme] = useState('')

  const dispatch = useDispatch();

  useEffect(() =>
    dispatch(GetdoctorDetailPreviewPage(doctorId))
    , [doctorId])

    useEffect(() => {
      setDoctorData(PreviewData && PreviewData?.Table6)
    }, [PreviewData]) 
{/* -------------------------------- theme set using theme id ---------------------------*/}
  useEffect(() => {
  switch (DoctorData && DoctorData[0].THEMEID) {
    case 1:
      setcolortheme('purple')
      break;
    case 2:
      setcolortheme('green')
      break;
    case 3:
      setcolortheme('hazy-blue')
      break;
    case 4:
      setcolortheme('digital-orangePink')
      break;
    case 6:
      setcolortheme('orange-tone')
      break;
    case 7:
      setcolortheme('blue-female')
      break;
    case 8:
      setcolortheme('gold-sliver')
      break;
    case 9:
      setcolortheme('bottle-green-blue')
      break;
    case 11:
      setcolortheme('pills-unicode')
      break;
    case 12:
        setcolortheme('clinic-new')
        break;
    default:
      break;
  }
  } ,[DoctorData])
  
  return <>
    {webConfigPreloader ? <ProLoaderShow /> :
    <div className={`App ${colortheme}`}>
      <div id={colortheme} className='bg-white '>
        <Header />
        <DoctorBanner />
        <DoctorInfo />
        <DoctorCard />
        <Doctorvideo />
        <ContactUs />
        <Footer />
      </div>
    </div>
      }
  </>



}
const mapStateToProps = (state) => {
  return {
    PreviewData: state.WebConfig.PreviewData?.Data?.Data,
    webConfigPreloader: state.WebConfig.webConfigPreloader,
  };
};
export default connect(mapStateToProps)(Home);