import Cookies from "universal-cookie";

export const Authcontext = (response) => {
  const cookies = new Cookies();
  if (response?.status === 401) {
    localStorage.removeItem("user");
    localStorage.clear();
    cookies.remove("token");
    window.location.reload();
  }
};

export const GetToken = () => {
  const cookies = new Cookies();
  const token = cookies.get("token");
  return token;
};
