export const actionTypes = {
  AFFILIATE_CONTECT_US_LISTING: "AFFILIATE_CONTECT_US_LISTING",
};

const INITIAL_STATE = {
  PatientQueries: [],
};

const PatientQueriesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // -----------------------------------AffiliateContactUsListing------------------------------------
    case actionTypes.AFFILIATE_CONTECT_US_LISTING:
      return {
        ...state,
        PatientQueries: action.PatientQueries,
      };
    default:
      return state;
  }
};

export default PatientQueriesReducer;
