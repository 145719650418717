const initialState = {
  auth: {
    email: "",
    idToken: "",
    localId: "",
    expiresIn: "",
    refreshToken: "",
  },
  errorMessage: "",
  successMessage: "",
  islogout: false,
};
export const actionTypes = {
  SET_CHANGE_PASSWORD: "SET_CHANGE_PASSWORD",
  LOADING_TOGGLE_ACTION: "LOADING_TOGGLE_ACTION",
  LOGIN_CONFIRMED_ACTION: "LOGIN_CONFIRMED_ACTION",
  LOGIN_FAILED_ACTION: "LOGIN_FAILED_ACTION",
  LOGOUT_ACTION: "LOGOUT_ACTION",
  SIGNUP_CONFIRMED_ACTION: "SIGNUP_CONFIRMED_ACTION",
  SIGNUP_FAILED_ACTION: "SIGNUP_FAILED_ACTION",
};

export function AuthReducer(state = initialState, action) {
  if (action.type === actionTypes.LOGOUT_ACTION) {
    return {
      ...state,
      errorMessage: "",
      successMessage: "",
      islogout: true,
      auth: {
        email: "",
        idToken: "",
        localId: "",
        expiresIn: "",
        refreshToken: "",
      },
    };
  }
  return state;
}
