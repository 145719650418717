export const actionTypes = {
  GET_CURRENT_HEALTH_PLAN: "GET_CURRENT_HEALTH_PLAN",
  GET_PAYMENT_DATA_BY_ID: "GET_PAYMENT_DATA_BY_ID",
  GET_INVOICE_FORVIEW_BY_ID: "GET_INVOICE_FORVIEW_BY_ID",
  GET_INVOICE_PDF: "GET_INVOICE_PDF",
  GET_PAYMENT_DATA_RENEW_BY_ID: "GET_PAYMENT_DATA_RENEW_BY_ID",
  SET_PRE_LOADER: "SET_PRE_LOADER",
  SET_PRE_LOADER_DETAIL: "SET_PRE_LOADER_DETAIL",
  GET_ALL_PLAN_DETAILS: "GET_ALL_PLAN_DETAILS",
  GET_PAYMENT_DETAIL_BY_USERID: "GET_PAYMENT_DETAIL_BY_USERID",
  REGISTRATION_SAVE_FOR_PAYMENT: "REGISTRATION_SAVE_FOR_PAYMENT",
  REGION_HEALTH_PLAN: "REGION_HEALTH_PLAN",
  SET_HEALTHPLAN_FEE_ID: "SET_HEALTHPLAN_FEE_ID",
  GET_TREATMENT_USERPLAN_HEAD_ID_NEW: "GET_TREATMENT_USERPLAN_HEAD_ID_NEW",
  GET_TREATMENT_USERPLAN_HEAD_ID_RENEW: "GET_TREATMENT_USERPLAN_HEAD_ID_RENEW",
  GET_TREATMENT_FAMILY_MEMBER_LIST: "GET_TREATMENT_FAMILY_MEMBER_LIST",
  IS_VALIDATING_PROMOCODE: "IS_VALIDATING_PROMOCODE",
  REMOVE_PROMOCODE: "REMOVE_PROMOCODE",
  SET_TREAMENT_PLAN_PAY_CONFIRM: "SET_TREAMENT_PLAN_PAY_CONFIRM",
  GET_OFFLINE_PAYMENT_LIST: "GET_OFFLINE_PAYMENT_LIST",
  OFFLINE_PAYMENT_SAVE: "OFFLINE_PAYMENT_SAVE",
  OFFLINE_FILE_UPLOAD: "OFFLINE_FILE_UPLOAD",
  GET_TREATMENTPLAN_DETAIL_BY_PLAN: "GET_TREATMENTPLAN_DETAIL_BY_PLAN",
  OFFLINE_PAYMENT_CANCEL: "OFFLINE_PAYMENT_CANCEL",
  GET_DETAIL_BY_USERID: "GET_DETAIL_BY_USERID",
  UPDATE_DETAIL_BY_USERID: "UPDATE_DETAIL_BY_USERID",
  GET_DISCOUNT_AMOUNT: "GET_DISCOUNT_AMOUNT",
  GET_USERPLAN_HEAD_ID: "GET_USERPLAN_HEAD_ID",
  GET_DOCTORPLAN_LIST: "GET_DOCTORPLAN_LIST",
  GET_DOCTORPLAN_CART: "GET_DOCTORPLAN_CART",
  GET_COUPON_CODE: "GET_COUPON_CODE",
  RESET_COUPON_CODE: "RESET_COUPON_CODE",
  GET_SHIPPING_DATA : 'GET_SHIPPING_DATA',
  GET_USERPLAN_FEES_IDS : 'GET_USERPLAN_FEES_IDS',
  GET_PLAN_FEES_IDS : 'GET_PLAN_FEES_IDS',
  GET_PLAN_COUNTRY_LIST : 'GET_PLAN_COUNTRY_LIST',
  GET_PLAN_COUNTRY_LIST_INDIA : 'GET_PLAN_COUNTRY_LIST_INDIA'

};

const INITIAL_STATE = {
  CurrentHealthPlan: [],
  PaymentData: [],
  PaymentDataRenew: [],
  ViewInvoiceData: [],
  AllplanDetail: [],
  PreloaderHP: false,
  PaymentDetailbyID: [],
  RegistrationSave: [],
  RegionHealthPlan: [],
  HealthPlanFeesId: "",
  TreatmentPlanUserPlanHeadIdnew: [],
  TreatmentPlanUserPlanHeadIdRenew: [],
  PlanFamilyMemberList: [],
  ValidPromoCodeData: [],
  RemovePromoCodeData: [],
  PaymentPlanConfirm: [],
  InvoicePdf: [],
  OfflinePaymentList: [],
  OfflinePamentSave: [],
  OfflinePaymentfiles: [],
  treatmentPlanByUseId: [],
  OfflinePaymentCancels: [],
  userDetail: [],
  updateuserDetail: [],
  Preloaderdetail: false,
  DiscountAmount: [],
  newuserplanheadid: null,
  DoctorPlan: [],
  DoctorCart: [],
  Newcouponcode : [],
  ShippingData : null,
  userPlanfeeIds : [],
  PlanfeeIds : [],
  plancountrylist : [],
  plancountrylistindia : []
};

const MyHealthPlanReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // -----------------------------------Get patient PROFILE------------------------------------
    case actionTypes.GET_CURRENT_HEALTH_PLAN:
      return {
        ...state,
        CurrentHealthPlan: action.CurrentHealthPlan,
        PreloaderHP: action.PreloaderHP,
      };
    // -----------------------------------Get payment detail by id------------------------------------
    case actionTypes.GET_PAYMENT_DATA_BY_ID:
      return {
        ...state,
        PaymentData: action.PaymentData,
      };
    case actionTypes.GET_USERPLAN_FEES_IDS :
      return {
        ...state,
        userPlanfeeIds: action.userPlanfeeIds,
      };
    case actionTypes.GET_PLAN_FEES_IDS :
      return {
        ...state,
        PlanfeeIds: action.PlanfeeIds,
      };
    // -----------------------------------Get payment detail RENEW by id------------------------------------
    case actionTypes.GET_PAYMENT_DATA_RENEW_BY_ID:
      return {
        ...state,
        PaymentDataRenew: action.PaymentDataRenew,
      };
    case actionTypes.GET_USERPLAN_HEAD_ID:
      return {
        ...state,
        newuserplanheadid: action.newuserplanheadid,
      };
    // -----------------------------------Get invoice detail by id------------------------------------
    case actionTypes.GET_INVOICE_FORVIEW_BY_ID:
      return {
        ...state,
        ViewInvoiceData: action.ViewInvoiceData,
      };
    case actionTypes.GET_SHIPPING_DATA:
      return {
        ...state,
        ShippingData: action.ShippingData,
      };
    // ---------------------------------- GET_INVOICE_PDF -----------------------------------
    case actionTypes.GET_INVOICE_PDF:
      return {
        ...state,
        InvoicePdf: action.InvoicePdf,
      };
    // -----------------------------------Get invoice detail by id------------------------------------
    case actionTypes.GET_ALL_PLAN_DETAILS:
      return {
        ...state,
        AllplanDetail: action.AllplanDetail,
      };
    // -----------------------------------get payment detail by userId------------------------------------
    case actionTypes.GET_PAYMENT_DETAIL_BY_USERID:
      return {
        ...state,
        PaymentDetailbyID: action.PaymentDetailbyID,
        PreloaderHP: action.PreloaderHP,
      };
    // -----------------------------------registration payment save------------------------------------
    case actionTypes.REGISTRATION_SAVE_FOR_PAYMENT:
      return {
        ...state,
        RegistrationSave: action.RegistrationSave,
        PreloaderHP: action.PreloaderHP,
      };
    // -----------------------------------region health plan------------------------------------
    case actionTypes.REGION_HEALTH_PLAN:
      return {
        ...state,
        RegionHealthPlan: action.RegionHealthPlan,
        PreloaderHP: action.PreloaderHP,
      };
    // -----------------------------------Get Treatment PlanFamily Member List ------------------------------------

    case actionTypes.GET_TREATMENT_USERPLAN_HEAD_ID_NEW:
      return {
        ...state,
        TreatmentPlanUserPlanHeadIdnew: action.TreatmentPlanUserPlanHeadIdnew,
      };
    // -----------------------------------Get Treatment PlanFamily Member List ------------------------------------

    case actionTypes.GET_TREATMENT_USERPLAN_HEAD_ID_RENEW:
      return {
        ...state,
        TreatmentPlanUserPlanHeadIdRenew:
          action.TreatmentPlanUserPlanHeadIdRenew,
      };
    // -----------------------------------Get Treatment PlanFamily Member List ------------------------------------

    case actionTypes.GET_TREATMENT_FAMILY_MEMBER_LIST:
      return {
        ...state,
        PlanFamilyMemberList: action.PlanFamilyMemberList,
        PreloaderHP: action.PreloaderHP,
      };
    // -----------------------------------SET PRELODER------------------------------------

    case actionTypes.SET_HEALTHPLAN_FEE_ID:
      localStorage.setItem(
        "planfeesId",
        JSON.stringify(action.HealthPlanFeesId)
      );
      return {
        ...state,
        HealthPlanFeesId: action.HealthPlanFeesId,
      };
    //-------------------------- Validate Promotional Coupon Code ---------------------
    case actionTypes.IS_VALIDATING_PROMOCODE:
      return {
        ...state,
        ValidPromoCodeData: action.ValidPromoCodeData,
        PreloaderHP: action.PreloaderHP,
      };
    //-------------------------- remove Promotional Coupon Code ---------------------
    case actionTypes.REMOVE_PROMOCODE:
      return {
        ...state,
        RemovePromoCodeData: action.RemovePromoCodeData,
        PreloaderHP: action.PreloaderHP,
      };
    //-------------------------- Treatment Payment Plan Confirm ---------------------
    case actionTypes.SET_TREAMENT_PLAN_PAY_CONFIRM:
      return {
        ...state,
        PaymentPlanConfirm: action.PaymentPlanConfirm,
        PreloaderHP: action.PreloaderHP,
      };
    //-------------------------- Treatment Payment Plan Confirm ---------------------
    case actionTypes.GET_OFFLINE_PAYMENT_LIST:
      return {
        ...state,
        OfflinePaymentList: action.OfflinePaymentList,
      };
    case actionTypes.OFFLINE_PAYMENT_SAVE:
      return {
        ...state,
        OfflinePamentSave: action.OfflinePamentSave,
        PreloaderHP: action.PreloaderHP,
      };
    case actionTypes.OFFLINE_FILE_UPLOAD:
      return {
        ...state,
        OfflinePaymentfiles: action.OfflinePaymentfiles,
      };
    case actionTypes.GET_TREATMENTPLAN_DETAIL_BY_PLAN:
      return {
        ...state,
        treatmentPlanByUseId: action.treatmentPlanByUseId,
      };
    case actionTypes.OFFLINE_PAYMENT_CANCEL:
      return {
        ...state,
        OfflinePaymentCancels: action.OfflinePaymentCancels,
        PreloaderHP: action.PreloaderHP,
      };
    case actionTypes.GET_DETAIL_BY_USERID:
      return {
        ...state,
        userDetail: action.userDetail,
        Preloaderdetail: action.Preloaderdetail,
      };
    case actionTypes.GET_DISCOUNT_AMOUNT:
      return {
        ...state,
        DiscountAmount: action.DiscountAmount,
        Preloaderdetail: action.Preloaderdetail,
      };
    case actionTypes.GET_DOCTORPLAN_LIST:
      return {
        ...state,
        DoctorPlan: action.DoctorPlan,
      };
    case actionTypes.GET_DOCTORPLAN_CART:
      return {
        ...state,
        DoctorCart: action.DoctorCart,
      };

    case actionTypes.GET_COUPON_CODE:
      return {
        ...state,
        Newcouponcode: action.Newcouponcode,
      };

  
    case actionTypes.RESET_COUPON_CODE:
      return {
        ...state,
        Newcouponcode: INITIAL_STATE.Newcouponcode,
      };

      case actionTypes.GET_PLAN_COUNTRY_LIST:
        return {
          ...state,
          plancountrylist: action.plancountrylist,
        };
      case actionTypes.GET_PLAN_COUNTRY_LIST_INDIA:
        return {
          ...state,
          plancountrylistindia: action.plancountrylistindia,
        };


    // -----------------------------------SET PRELODER------------------------------------

    case actionTypes.SET_PRE_LOADER:
      return {
        ...state,
        PreloaderHP: action.PreloaderHP,
      };
    case actionTypes.SET_PRE_LOADER_DETAIL:
      return {
        ...state,
        Preloaderdetail: action.Preloaderdetail,
      };

    default:
      return state;
  }
};

export default MyHealthPlanReducer;
