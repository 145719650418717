export const actionTypes = {
  GET_CASEHISTORYFORM: "GET_CASEHISTORYFORM",
  GET_CASEHISTORY_DETAIL: "GET_CASEHISTORY_DETAIL",
  GET_MAIN_PRISCRIPTION: "GET_MAIN_PRISCRIPTION",
  GET_EMERGENCY_MEDICINES: "GET_EMERGENCY_MEDICINES",
  GET_PRESCRIPTION_ANALYSIS: "GET_PRESCRIPTION_ANALYSIS",
  GET_REMEDY_ABBREVATION_SUGGESTIONS_LIST:
    "GET_REMEDY_ABBREVATION_SUGGESTIONS_LIST",
  GET_REMEDY_SUGGESTIONS_LIST: "GET_REMEDY_SUGGESTIONS_LIST",
  GET_REMEDY_FORM_SUGGESTIONS_LIST: "GET_REMEDY_FORM_SUGGESTIONS_LIST",
  GET_POTENCY_SUGGESTIONS_LIST: "GET_POTENCY_SUGGESTIONS_LIST",
  GET_REPETITION_SUGGESTIONS_LIST: "GET_REPETITION_SUGGESTIONS_LIST",
  GET_DIAGNOSIS_SUGGESTIONS_LIST: "GET_DIAGNOSIS_SUGGESTIONS_LIST",
  GET_CONSULTATION_LISTING: "GET_CONSULTATION_LISTING",
  GET_PAT_START_ANALYSIS: "GET_PAT_START_ANALYSIS",
  GET_FOLLOWUP_COMPLAINTS: "GET_FOLLOWUP_COMPLAINTS",
  GET_VIEWMEDICINE: "GET_VIEWMEDICINE",
  GET_SPECIAL_INSTRUCTION: "GET_SPECIAL_INSTRUCTION",
  GET_SETTINGS_KEY_VALUE: "GET_SETTINGS_KEY_VALUE",
  GET_ANALYSIS_DETAILS: "GET_ANALYSIS_DETAILS",
  GET_NEXT_FOLLOWUP_DATE_ANALYS: "GET_NEXT_FOLLOWUP_DATE_ANALYS",
  GET_EDIT_PRISCRIPTION_DETAIL: "GET_EDIT_PRISCRIPTION_DETAIL",
  RESET_EDIT_PRISCRIPTION_DETAIL: "RESET_EDIT_PRISCRIPTION_DETAIL",
  SET_PRE_LOADER: "SET_PRE_LOADER",
  SET_CONSULT_PRE_LOADER: "SET_CONSULT_PRE_LOADER",
  GET_FOLLOWUP_QUN_LIST: "GET_FOLLOWUP_QUN_LIST",
  GET_PRISCRIPTION_LIST: "GET_PRISCRIPTION_LIST",
  GET_FOLLOWUP_COMPLAIN_LIST: "GET_FOLLOWUP_COMPLAIN_LIST",
  GET_FOLLOWUP_COMPLAIN_LIST_BY_Id: "GET_FOLLOWUP_COMPLAIN_LIST_BY_Id",
  GET_FOLLOWUP_CRITERIA_DETAIL: "GET_FOLLOWUP_CRITERIA_DETAIL",
  COMPLETE_ANALYSIS: "COMPLETE_ANALYSIS",
  VIEW_ANALYSIS: "VIEW_ANALYSIS",
  DELETE_CONSULTATION: "DELETE_CONSULTATION",
  RESET_PRISCRIPTIONLIST: "RESET_PRISCRIPTIONLIST",
  RESET_ANALYSIS_DETAILS: "RESET_ANALYSIS_DETAILS",
  GET_MEDICINE_NAME: "GET_MEDICINE_NAME",
  RESET_MEDICINE_NAME: "RESET_MEDICINE_NAME",
  GET_ABBREVATION_NAME: "GET_ABBREVATION_NAME",
  RESET_NEXT_FOLLOWUP_DATE_ANALYS: "RESET_NEXT_FOLLOWUP_DATE_ANALYS",
  RESET_CONSULTATION_LISTING : 'RESET_CONSULTATION_LISTING',
  GET_MOBILE_CODE: 'GET_MOBILE_CODE',
  GET_NEW_MOBILE_CODE: 'GET_NEW_MOBILE_CODE'
};

export const NewactionTypes = {
  RESET_CASEHISTORYFORM: "RESET_CASEHISTORYFORM",
};
const INITIAL_STATE = {
  CaseHistoryForm: [],
  mainpriscriptionlist: [],
  EmergencyMedicineslist: [],
  PrescriptionByAnalysislist: [],
  RemedyAbbrevationSuggestionsList: [],
  RemedySuggestionsList: [],
  RemedyFormSuggestionsList: [],
  PotencySuggestionsList: [],
  RepetitionSuggestionsList: [],
  DiagnosisSuggestionsList: [],
  ConsultationListing: [],
  StartAnalysis: [],
  FollowupComplaints: [],
  ViewMedicine: [],
  SpecialInstruction: [],
  defaultSpecialInstruction: [],
  AnalysisDetail: [],
  NextFollowUpDateByAnalys: [],
  EditPrescriptionDetail: [],
  FollowUpQunList: [],
  PreloaderCH: false,
  PreloaderCHH: false,
  PrescriptionLists: [],
  FollowUpComplaintList: [],
  FollowUpComplaintsById: [],
  FollowUpCriteriaDetail: [],
  completeanalysis: [],
  viewanalysis: [],
  casehistorydetail: [],
  DeleteConsultation: [],
  MedicineNames: [],
  AbbrevationNames: [],
  mobilecode: [],
  newmobilecode: []
};

const CaseHistoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // -----------------------------------GET_CASEHISTORYFORM------------------------------------
    case actionTypes.GET_CASEHISTORYFORM:
      return {
        ...state,
        CaseHistoryForm: action.CaseHistoryForm,
        PreloaderCH: action.PreloaderCH,
      };

    case actionTypes.GET_CASEHISTORY_DETAIL:
      return {
        ...state,
        casehistorydetail: action.casehistorydetail,
        PreloaderCH: action.PreloaderCH,
      };
    case NewactionTypes.RESET_CASEHISTORYFORM:
      return {
        ...state,
        casehistorydetail: [],
      };
    // -----------------------------------GET_MAIN_PRISCRIPTION------------------------------------
    case actionTypes.GET_MAIN_PRISCRIPTION:
      return {
        ...state,
        mainpriscriptionlist: action.mainpriscriptionlist,
        PreloaderCH: action.PreloaderCH,
      };
    case actionTypes.RESET_PRISCRIPTIONLIST:
      return {
        ...state,
        mainpriscriptionlist: INITIAL_STATE.mainpriscriptionlist,
        PreloaderCH: action.PreloaderCH,
      };
    // -----------------------------------GET_EMERGENCY_MEDICINES------------------------------------
    case actionTypes.GET_EMERGENCY_MEDICINES:
      return {
        ...state,
        EmergencyMedicineslist: action.EmergencyMedicineslist,
        PreloaderCH: action.PreloaderCH,
      };
    // -----------------------------------GET_PRESCRIPTION_ANALYSIS------------------------------------
    case actionTypes.GET_PRESCRIPTION_ANALYSIS:
      return {
        ...state,
        PrescriptionByAnalysislist: action.PrescriptionByAnalysislist,
        PreloaderCH: action.PreloaderCH,
      };
    case actionTypes.GET_MEDICINE_NAME:
      return {
        ...state,
        MedicineNames: action.MedicineNames,
      };
    case actionTypes.GET_ABBREVATION_NAME:
      return {
        ...state,
        AbbrevationNames: action.AbbrevationNames,
      };
    case actionTypes.RESET_MEDICINE_NAME:
      return {
        ...state,
        MedicineNames: INITIAL_STATE.MedicineNames,
      };
    // -----------------------------------GET_PRESCRIPTION_ANALYSIS------------------------------------
    case actionTypes.COMPLETE_ANALYSIS:
      return {
        ...state,
        completeanalysis: action.completeanalysis,
        PreloaderCH: action.PreloaderCH,
      };
    case actionTypes.VIEW_ANALYSIS:
      return {
        ...state,
        viewanalysis: action.viewanalysis,
        PreloaderCH: action.PreloaderCH,
      };
    case actionTypes.DELETE_CONSULTATION:
      return {
        ...state,
        DeleteConsultation: action.DeleteConsultation,
        PreloaderCH: action.PreloaderCH,
      };
    // -----------------------------------GET_REMEDY_ABBREVATION_SUGGESTIONS_LIST------------------------------------
    case actionTypes.GET_REMEDY_ABBREVATION_SUGGESTIONS_LIST:
      return {
        ...state,
        RemedyAbbrevationSuggestionsList:
          action.RemedyAbbrevationSuggestionsList,
        PreloaderCH: action.PreloaderCH,
      };
    // -----------------------------------GET_REMEDY_SUGGESTIONS_LIST------------------------------------
    case actionTypes.GET_REMEDY_SUGGESTIONS_LIST:
      return {
        ...state,
        RemedySuggestionsList: action.RemedySuggestionsList,
        PreloaderCH: action.PreloaderCH,
      };
    // -----------------------------------GET_REMEDY_FORM_SUGGESTIONS_LIST------------------------------------
    case actionTypes.GET_REMEDY_FORM_SUGGESTIONS_LIST:
      return {
        ...state,
        RemedyFormSuggestionsList: action.RemedyFormSuggestionsList,
        PreloaderCH: action.PreloaderCH,
      };
    // -----------------------------------GET_POTENCY_SUGGESTIONS_LIST------------------------------------
    case actionTypes.GET_POTENCY_SUGGESTIONS_LIST:
      return {
        ...state,
        PotencySuggestionsList: action.PotencySuggestionsList,
        PreloaderCH: action.PreloaderCH,
      };
    // -----------------------------------GET_REPETITION_SUGGESTIONS_LIST------------------------------------
    case actionTypes.GET_REPETITION_SUGGESTIONS_LIST:
      return {
        ...state,
        RepetitionSuggestionsList: action.RepetitionSuggestionsList,
        PreloaderCH: action.PreloaderCH,
      };
    // -----------------------------------GET_DIAGNOSIS_SUGGESTIONS_LIST------------------------------------
    case actionTypes.GET_DIAGNOSIS_SUGGESTIONS_LIST:
      return {
        ...state,
        DiagnosisSuggestionsList: action.DiagnosisSuggestionsList,
        PreloaderCH: action.PreloaderCH,
      };
    // -----------------------------------GET_CONSULTATION_LISTING------------------------------------
    case actionTypes.GET_CONSULTATION_LISTING:
      return {
        ...state,
        ConsultationListing: action.ConsultationListing,
        PreloaderCHH: action.PreloaderCHH,
      };
    case actionTypes.RESET_CONSULTATION_LISTING:
      return {
        ...state,
        ConsultationListing: INITIAL_STATE.ConsultationListing,
      };
    // -----------------------------------GET_PAT_START_ANALYSIS------------------------------------
    case actionTypes.GET_PAT_START_ANALYSIS:
      return {
        ...state,
        StartAnalysis: action.StartAnalysis,
        PreloaderCH: action.PreloaderCH,
      };
    // -----------------------------------GET_FOLLOWUP_COMPLAINTS------------------------------------
    case actionTypes.GET_FOLLOWUP_COMPLAINTS:
      return {
        ...state,
        FollowupComplaints: action.FollowupComplaints,
        PreloaderCH: action.PreloaderCH,
      };
    // -----------------------------------GET_VIEWMEDICINE------------------------------------
    case actionTypes.GET_VIEWMEDICINE:
      return {
        ...state,
        ViewMedicine: action.ViewMedicine,
        PreloaderCH: action.PreloaderCH,
      };
    // -----------------------------------GET_SPECIAL_INSTRUCTION------------------------------------
    case actionTypes.GET_SPECIAL_INSTRUCTION:
      return {
        ...state,
        SpecialInstruction: action.SpecialInstruction,
        PreloaderCH: action.PreloaderCH,
      };
    // -----------------------------------GET_SETTINGS_KEY_VALUE------------------------------------
    case actionTypes.GET_SETTINGS_KEY_VALUE:
      return {
        ...state,
        defaultSpecialInstruction: action.defaultSpecialInstruction,
        PreloaderCH: action.PreloaderCH,
      };
    // -----------------------------------GET_NEXT_FOLLOWUP_DATE_ANALYS------------------------------------
    case actionTypes.GET_ANALYSIS_DETAILS:
      return {
        ...state,
        AnalysisDetail: action.AnalysisDetail,
        PreloaderCH: action.PreloaderCH,
      };
    case actionTypes.RESET_ANALYSIS_DETAILS:
      return {
        ...state,
        AnalysisDetail: INITIAL_STATE.AnalysisDetail,
      };
    // -----------------------------------GET_NEXT_FOLLOWUP_DATE_ANALYS------------------------------------
    case actionTypes.GET_NEXT_FOLLOWUP_DATE_ANALYS:
      return {
        ...state,
        NextFollowUpDateByAnalys: action.NextFollowUpDateByAnalys,
        PreloaderCH: action.PreloaderCH,
      };
    case actionTypes.RESET_NEXT_FOLLOWUP_DATE_ANALYS:
      return {
        ...state,
        NextFollowUpDateByAnalys: INITIAL_STATE.NextFollowUpDateByAnalys,
      };
    // -----------------------------------GET_EDIT_PRISCRIPTION_DETAIL------------------------------------
    case actionTypes.GET_EDIT_PRISCRIPTION_DETAIL:
      return {
        ...state,
        EditPrescriptionDetail: action.EditPrescriptionDetail,
        PreloaderCH: action.PreloaderCH,
      };
    case actionTypes.RESET_EDIT_PRISCRIPTION_DETAIL:
      return {
        ...state,
        EditPrescriptionDetail: INITIAL_STATE.EditPrescriptionDetail,
      };
    // -----------------------------------GET_EDIT_PRISCRIPTION_DETAIL------------------------------------
    case actionTypes.GET_FOLLOWUP_QUN_LIST:
      return {
        ...state,
        FollowUpQunList: action.FollowUpQunList,
        PreloaderCH: action.PreloaderCH,
      };
    // -----------------------------------GET_PRISCRIPTION_LIST------------------------------------

    case actionTypes.GET_PRISCRIPTION_LIST:
      return {
        ...state,
        PrescriptionLists: action.PrescriptionLists,
        PreloaderCH: action.PreloaderCH,
      };
    // -----------------------------------GET_FOLLOWUP_COMPLAIN_LIST------------------------------------

    case actionTypes.GET_FOLLOWUP_COMPLAIN_LIST:
      return {
        ...state,
        FollowUpComplaintList: action.FollowUpComplaintList,
        PreloaderCH: action.PreloaderCH,
      };
    // -----------------------------------GET_FOLLOWUP_COMPLAIN_LIST_BY_Id------------------------------------

    case actionTypes.GET_FOLLOWUP_COMPLAIN_LIST_BY_Id:
      return {
        ...state,
        FollowUpComplaintsById: action.FollowUpComplaintsById,
        PreloaderCH: action.PreloaderCH,
      };
    // -----------------------------------GET_FOLLOWUP_CRITERIA_DETAIL------------------------------------

    case actionTypes.GET_FOLLOWUP_CRITERIA_DETAIL:
      return {
        ...state,
        FollowUpCriteriaDetail: action.FollowUpCriteriaDetail,
        PreloaderCH: action.PreloaderCH,
      };

    case actionTypes.GET_MOBILE_CODE:
      return {
        ...state,
        mobilecode: action.mobilecode,
      };
    case actionTypes.GET_NEW_MOBILE_CODE:
      return {
        ...state,
        newmobilecode: action.newmobilecode,
      };
    // -----------------------------------SET_PRE_LOADER------------------------------------
    case actionTypes.SET_PRE_LOADER:
      return {
        ...state,
        PreloaderCH: action.PreloaderCH,
      };
    // -----------------------------------ET_CONSULT_PRE_LOADER------------------------------------
    case actionTypes.SET_CONSULT_PRE_LOADER:
      return {
        ...state,
        PreloaderCHH: action.PreloaderCHH,
      };

    default:
      return state;
  }
};

export default CaseHistoryReducer;
