import axios from "axios";
import { toast } from "react-toastify";
import { Authcontext, GetToken } from "../Authcontext";

export const actionTypes = {
  SET_HOMEPAGE_DETAIL: "SET_HOMEPAGE_DETAIL",
  SET_PROFILE_IMAGE: "SET_PROFILE_IMAGE",
  SET_LOGO_IMAGE: " SET_LOGO_IMAGE",
  GET_DOCTOR_DETAIL: "GET_DOCTOR_DETAIL",
  GET_SOCIAL_ICONS_LIST: "GET_SOCIAL_ICONS_LIST",
  SOCIAL_MEDIA_ADD_ICON_LIST: "SOCIAL_MEDIA_ADD_ICON_LIST",
  GET_GELLERY_EVENTLIST: "GET_GELLERY_EVENTLIST",
  GET_GELLERY_LIST: "GET_GELLERY_LIST",
  GET_AFFILIATE_PROFILE_SPECIALITIES_BY_USER_ID:
    "GET_AFFILIATE_PROFILE_SPECIALITIES_BY_USER_ID",
  GET_AFFILIATE_DOCTOR_QUALIFICATION_LIST:
    "GET_AFFILIATE_DOCTOR_QUALIFICATION_LIST",
  GET_AFFILIATE_QUALIFICATION_BY_ID: "GET_AFFILIATE_QUALIFICATION_BY_ID",
  GET_AFFILIATE_QUALIFICATION_UPDATE: "GET_AFFILIATE_QUALIFICATION_UPDATE",
  GET_AFFILIATE_QUALIFICATION_SAVE: "GET_AFFILIATE_QUALIFICATION_SAVE",
  GET_AFFILIATE_HEALTH_PLAN_ID: "GET_AFFILIATE_HEALTH_PLAN_ID",
  GET_THEME_LIST: "GET_THEME_LIST",
  GET_THEME_URL: "GET_THEME_URL",
  GET_REVENUE_SHARE: "GET_REVENUE_SHARE",
  GET_BILLING_PLAN: "GET_BILLING_PLAN",
  GET_ACCOUNT_STATUS: "GET_ACCOUNT_STATUS",
  GET_TESTIMONIAL_TEXT: "GET_TESTIMONIAL_TEXT",
  GET_TESTIMONIAL_VIDEO: "GET_TESTIMONIAL_VIDEO",
  GET_DOCUMENT_LIST: "GET_DOCUMENT_LIST",
  DELETE_AFFILIATE_QUALIFICATION: "DELETE_AFFILIATE_QUALIFICATION",
  DELETE_SOCIAL_ICON: "DELETE_SOCIAL_ICON",
  GET_WEBSITE_DOMAIN: "GET_WEBSITE_DOMAIN",
  EDIT_TESTIMONIAL_DATA: "EDIT_TESTIMONIAL_DATA",
  EDIT_HEALTH_PLAN: "EDIT_HEALTH_PLAN",
  GET_PREVIEW_PAGE_DATA: "GET_PREVIEW_PAGE_DATA",
  SET_PRE_LOADER_WEB_CONFIG: "SET_PRE_LOADER_WEB_CONFIG",
  DOMAIN_CHECK_AVAILABILITY: "DOMAIN_CHECK_AVAILABILITY",
  DOMAIN_REGISTRATION: "DOMAIN_REGISTRATION",
  GET_QUALIFICATION_SUGGESTION_LIST: "GET_QUALIFICATION_SUGGESTION_LIST",
};

/*-------------------------- GetDoctorDetail---------------------*/
export const GetDoctorDetail = () => {
  const loginData = JSON.parse(localStorage.getItem("user"));
  const token = GetToken();
  const Data = {
    UserId: loginData?.Data.Table[0].UserId,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Affiliate/GetAffiliateDoctorDataById`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: actionTypes.GET_DOCTOR_DETAIL,
          doctorDetail: response.data,
          webConfigPreloader: false,
        });
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- SaveDoctorDetail---------------------*/
export const SaveDoctorDetail = (
  EnterName,
  Email,
  Mobile,
  Address,
  Country,
  City,
  Zip,
  Age,
  planID
) => {
  const loginData = JSON.parse(localStorage.getItem("user"));
  const token = GetToken();
  const Data = {
    Name: EnterName,
    EmailId: Email,
    MobileNumber: Mobile,
    Address: Address,
    Gender: "Male",
    CountryId: Country,
    City: City,
    ZipCode: Zip,
    Age: Age,
    PlanId: planID,
    AffiliateDoctorUserID: loginData?.Data.Table[0].UserId,
  };

  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Affiliate/EditAffiliateDoctorRegistrationSave`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        toast.success(
          <>
            <span>Data Saved Successfully!!!</span>
          </>
        );
        dispatch({
          type: actionTypes.SET_PRE_LOADER_WEB_CONFIG,
          webConfigPreloader: false,
        });
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- GetWebsiteConfiguration---------------------*/
export const GetWebsiteConfiguration = () => {
  const loginData = JSON.parse(localStorage.getItem("user"));
  const token = GetToken();
  const Data = {
    UserId: loginData?.Data.Table[0].UserId,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/User/GetWebsiteConfigurationByUserId_New`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: actionTypes.SET_HOMEPAGE_DETAIL,
          homepageDetail: response.data.Data,
          webConfigPreloader: false,
        });
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- UpdateProfileImage---------------------*/
export const UpdateProfileImage = (ProfileImgFile) => {
  const token = GetToken();
  var Data = new FormData();
  var profileFileData = new File([ProfileImgFile], ProfileImgFile.name, {
    type: "application/json",
  });
  const loginData = JSON.parse(localStorage.getItem("user"));
  Data.append("Name", loginData?.Data.Table[0].Name);
  Data.append("DoctoruserId", loginData?.Data.Table[0].UserId);
  Data.append("ProfilePhoto", profileFileData);

  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Affiliate/AffiliateWebsiteConfigurationUpdateProfileImage`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: actionTypes.SET_PROFILE_IMAGE,
          profileImgLink: response.data,
          webConfigPreloader: false,
        });
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- UpdateLogoImg---------------------*/
export const UpdateLogoImg = (logoImgFile) => {
  const token = GetToken();
  const loginData = JSON.parse(localStorage.getItem("user"));
  var Data = new FormData();
  var logoFileData = new File([logoImgFile], logoImgFile.name, {
    type: "application/json",
  });
  Data.append("Name", loginData?.Data.Table[0].Name);
  Data.append("DoctoruserId", loginData?.Data.Table[0].UserId);
  Data.append("Logo", logoFileData);

  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Affiliate/AffiliateWebsiteConfigurationUpdateLogo`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: actionTypes.SET_LOGO_IMAGE,
          logoImgLink: response.data,
          webConfigPreloader: false,
        });
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- AffiliateWebsiteConfigurationUpdate---------------------*/
export const AffiliateWebsiteConfigurationUpdate = (
  AllData,
  logoLink,
  ProfileImglink
) => {
  const token = GetToken();
  const loginData = JSON.parse(localStorage.getItem("user"));

  const data = {
    AffiliateProfilePhoto: ProfileImglink || AllData.AffiliateProfilePhoto,
    Logo: logoLink || AllData.Logo,
    DoctoruserId: loginData?.Data.Table[0].UserId,
    ClinicName: AllData.ClinicName,
    MainHeaderText: AllData.MainHeaderText,
    SubheaderText: AllData.SubheaderText,
    USP1: AllData.USP1,
    USP2: AllData.USP2,
    USP3: AllData.USP3,
    TextTestimonialHeading: AllData.TextTestimonialHeading,
    TextTestimonialDesc: AllData.TextTestimonialDesc,
    VideoTestimonial: AllData.VideoTestimonial,
    MapUrl: AllData.MapUrl,
    Name: loginData?.Data.Table[0].Name,
    ThemeId: AllData.THEMEID,
  };

  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Affiliate/AffiliateWebsiteConfigurationUpdate`,
        data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        toast.success(
          <>
            <span>Data Saved Successfully!!!</span>
          </>
        );
        dispatch(GetWebsiteConfiguration());
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- GetAffiliateProfileSpeciliatiesByUserId---------------------*/
export const GetAffiliateProfileSpeciliatiesByUserId = () => {
  const token = GetToken();
  const loginData = JSON.parse(localStorage.getItem("user"));
  const Data = {
    UserId: loginData?.Data.Table[0].UserId,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Affiliate/GetAffiliateProfileSpeciliatiesByUserId`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: actionTypes.GET_AFFILIATE_PROFILE_SPECIALITIES_BY_USER_ID,
          ProfileSpecialitiesbyuser: response.data,
          webConfigPreloader: false,
        });
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- AffiliateProfileAndSpecialitiesUpdate---------------------*/
export const AffiliateProfileAndSpecialitiesUpdate = (
  Key,
  ProfileText,
  SpecialtiesText
) => {
  const loginData = JSON.parse(localStorage.getItem("user"));
  const token = GetToken();
  const Data = {
    UserId: loginData?.Data.Table[0].UserId,
    Key: Key,
    Value: ProfileText,
    AddtionalDisease: SpecialtiesText,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Affiliate/AffiliateProfileAndSpecialitiesUpdate`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        toast.success(
          <>
            <span>Data Saved Successfully!!!</span>
          </>
        );
        dispatch(GetAffiliateProfileSpeciliatiesByUserId());
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- GetAffiliateDcotorQualificationList---------------------*/
export const GetAffiliateDcotorQualificationList = () => {
  const loginData = JSON.parse(localStorage.getItem("user"));
  const token = GetToken();
  const Data = {
    StartRow: 0,
    PageSize: 100,
    SearchText: "",
    UserId: loginData?.Data.Table[0].UserId,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Affiliate/GetAffiliateDcotorQualificationList`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: actionTypes.GET_AFFILIATE_DOCTOR_QUALIFICATION_LIST,
          DoctorQualificationList: response.data,
          webConfigPreloader: false,
        });
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- GetAffiliateQualificationById---------------------*/
export const GetAffiliateQualificationById = (UserQualId) => {
  const token = GetToken();
  const Data = {
    UserQualId: UserQualId,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Affiliate/GetAffiliateQualificationById`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: actionTypes.GET_AFFILIATE_QUALIFICATION_BY_ID,
          QualificationByid: response.data,
          webConfigPreloader: false,
        });
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- AffiliateDoctorQualificationSave---------------------*/
export const AffiliateDoctorQualificationUpdate = (
  UserQualId,
  Qualification
) => {
  const loginData = JSON.parse(localStorage.getItem("user"));
  const token = GetToken();
  const Data = {
    UserQualId: UserQualId,
    Qualification: Qualification,
    UserId: loginData?.Data.Table[0].UserId,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Affiliate/AffiliateDoctorQualificationUpdate`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        toast.success(
          <>
            <span>Data Saved Successfully!!!</span>
          </>
        );
        dispatch(GetAffiliateDcotorQualificationList());
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- DeleteAffiliateQualificationById---------------------*/
export const DeleteAffiliateQualificationById = (UserQualId) => {
  const loginData = JSON.parse(localStorage.getItem("user"));
  const token = GetToken();
  const Data = {
    UserQualId: UserQualId,
    UserId: loginData?.Data.Table[0].UserId,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Affiliate/DeleteAffiliateQualificationById`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        toast.success(
          <>
            <span>Data Deleted Successfully!!!</span>
          </>
        );
        dispatch(GetAffiliateDcotorQualificationList());
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};

/*-------------------------- AffiliateDoctorQualificationSave---------------------*/
export const AffiliateDoctorQualificationSave = (Qualification) => {
  const loginData = JSON.parse(localStorage.getItem("user"));
  const token = GetToken();
  const Data = {
    UserQualId: "",
    Qualification: Qualification,
    UserId: loginData?.Data.Table[0].UserId,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Affiliate/AffiliateDoctorQualificationSave`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        toast.success(
          <>
            <span>Data Saved Successfully!!!</span>
          </>
        );
        dispatch(GetAffiliateDcotorQualificationList());
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};

/*-------------------------- Qualification List---------------------*/
export const GetQualificationSuggestionsList = (Qualification) => {
  const token = GetToken();
  const Data = {
    Qualification: Qualification,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Affiliate/GetAffiliateQualificationSuggestionsList`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: actionTypes.GET_QUALIFICATION_SUGGESTION_LIST,
          QualificationSuggestionsList: response.data,
          webConfigPreloader: false,
        });
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- GetAffiliateHealthPlanId---------------------*/
export const GetAffiliateHealthPlanId = () => {
  const loginData = JSON.parse(localStorage.getItem("user"));
  const token = GetToken();
  const Data = {
    UserId: loginData?.Data.Table[0].UserId,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Affiliate/GetAffiliateHealthPlanId`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: actionTypes.GET_AFFILIATE_HEALTH_PLAN_ID,
          AffiliateHealthPlan: response.data,
          webConfigPreloader: false,
        });
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- EditHealthPlanById---------------------*/
export const EditHealthPlanById = (id) => {
  const loginData = JSON.parse(localStorage.getItem("user"));
  const token = GetToken();
  const Data = {
    AffiliateDoctorUserid: loginData?.Data.Table[0].UserId,
    PlanRegionId: id,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/TreatmentPlans/AffiliatePlan`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: actionTypes.EDIT_HEALTH_PLAN,
          editHealthPlan: response.data,
          webConfigPreloader: false,
        });
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- UpdateAffiliatedoctorPlan---------------------*/
export const UpdateAffiliatedoctorPlan = (value) => {
  const loginData = JSON.parse(localStorage.getItem("user"));
  const token = GetToken();
  const Data = {
    AffiliateDoctorUserid: loginData?.Data.Table[0].UserId,
    PlanFeesId1: value[0].PlanFessId,
    Amount1: value[0].Amount,
    PlanFeesId2: value[1].PlanFessId,
    Amount2: value[1].Amount,
    PlanFeesId3: value[2].PlanFessId,
    Amount3: value[2].Amount,
    PlanFeesId4: value[3].PlanFessId,
    Amount4: value[3].Amount,
    PlanFeesId5: value[4].PlanFessId,
    Amount5: value[4].Amount,
    PlanFeesId6: value[5].PlanFessId,
    Amount6: value[5].Amount,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/TreatmentPlans/UpdateAffiliatedoctorPlan`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        toast.success(
          <>
            <span>Data Saved Successfully!!!</span>
          </>
        );
        dispatch(GetAffiliateHealthPlanId());
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- GetThemeUrl---------------------*/
export const GetThemeUrl = () => {
  const loginData = JSON.parse(localStorage.getItem("user"));
  const token = GetToken();
  const Data = {
    UserId: loginData?.Data.Table[0].UserId,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/User/GetWebsiteThemeByUserId`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: actionTypes.GET_THEME_URL,
          themeUrllist: response.data.Data,
          webConfigPreloader: false,
        });
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- GetThemeList---------------------*/
export const GetThemeList = () => {
  const loginData = JSON.parse(localStorage.getItem("user"));
  const token = GetToken();
  const Data = {
    UserId: loginData?.Data.Table[0].UserId,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Country/GetThemeList`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: actionTypes.GET_THEME_LIST,
          themelist: response.data,
          webConfigPreloader: false,
        });
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- SaveWebsiteTheme---------------------*/
export const SaveWebsiteTheme = (themeID) => {
  const loginData = JSON.parse(localStorage.getItem("user"));
  const token = GetToken();
  const Data = {
    UserId: loginData?.Data.Table[0].UserId,
    ThemeId: themeID,
  };

  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/User/SavewebsiteThemeByUserId`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        toast.success(
          <>
            <span>Data Saved Successfully!!!</span>
          </>
        );
        dispatch(GetThemeUrl());
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- GetSocialIconList---------------------*/
export const GetSocialIconList = () => {
  const loginData = JSON.parse(localStorage.getItem("user"));
  const token = GetToken();
  const Data = {
    UserId: loginData?.Data.Table[0].UserId,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Affiliate/GetAffiliateSocialMediaIconList`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: actionTypes.GET_SOCIAL_ICONS_LIST,
          socialIconList: response.data,
          webConfigPreloader: false,
        });
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- GetAffiliateSocialMediaAddedIconList---------------------*/
export const GetAffiliateSocialMediaAddedIconList = (IconDetail) => {
  const loginData = JSON.parse(localStorage.getItem("user"));
  const token = GetToken();
  const Data = {
    ...IconDetail,
    UserId: loginData?.Data.Table[0].UserId,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Affiliate/GetAffiliateSocialMediaAddedIconList`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        toast.success(
          <>
            <span>Data Saved Successfully!!!</span>
          </>
        );
        dispatch(GetSocialIconList());
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- DeleteSocialIcon---------------------*/
export const DeleteSocialIcon = (iconId) => {
  const loginData = JSON.parse(localStorage.getItem("user"));
  const token = GetToken();
  const Data = {
    UserId: loginData?.Data.Table[0].UserId,
    IconId: iconId,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Affiliate/DeleteAffiliateSocialMediaAddedIconList`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        toast.success(
          <>
            <span>Data Deleted Successfully!!!</span>
          </>
        );
        dispatch(GetSocialIconList());
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- SaveSocialIconData---------------------*/
export const SaveSocialIconData = (iconId, redirectUrl) => {
  const loginData = JSON.parse(localStorage.getItem("user"));
  const token = GetToken();
  const Data = {
    UserId: loginData?.Data.Table[0].UserId,
    IconId: iconId,
    RedirectUrl: redirectUrl,
  };

  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Affiliate/SaveAffiliateSocialMediaAddedIconList`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        toast.success(
          <>
            <span>Data Saved Successfully!!!</span>
          </>
        );
        dispatch(GetSocialIconList());
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};

/*-------------------------- GetGelleryEventList---------------------*/
export const GetGelleryEventList = () => {
  const loginData = JSON.parse(localStorage.getItem("user"));
  const token = GetToken();
  const Data = {
    UserId: loginData?.Data.Table[0].UserId,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Affiliate/GetAffiliateGallery`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: actionTypes.GET_GELLERY_EVENTLIST,
          gelleryEventList: response.data,
          webConfigPreloader: false,
        });
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- GetAffiliatePhotoEdit---------------------*/
export const GetAffiliatePhotoEdit = (photoId) => {
  const loginData = JSON.parse(localStorage.getItem("user"));
  const token = GetToken();
  const Data = {
    UserId: loginData?.Data.Table[0].UserId,
    PhotoId: photoId,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Affiliate/GetAffiliatePhotoEdit`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: actionTypes.GET_GELLERY_LIST,
          gelleryList: response.data,
          webConfigPreloader: false,
        });
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- UpdateGelleryList---------------------*/
export const UpdateGelleryList = (
  PhotoId,
  Category,
  galleryTitle,
  sDescription
) => {
  const loginData = JSON.parse(localStorage.getItem("user"));
  const token = GetToken();
  const Data = {
    UserId: loginData?.Data.Table[0].UserId,
    PhotoId: PhotoId,
    CategoryId: Category,
    Title: galleryTitle,
    ShortDescription: sDescription,
  };

  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Affiliate/EditGalleryPhotoDetailsUpdate`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        toast.success(
          <>
            <span>Data Saved Successfully!!!</span>
          </>
        );
        dispatch(GetGelleryEventList());
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- PhotoDelete---------------------*/

export const PhotoDelete = (PhotoId) => {
  const token = GetToken();
  const Data = {
    PhotoID: PhotoId,
  };

  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Affiliate/PhotoDelete`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        toast.success(
          <>
            <span>Data Deleted Successfully!!!</span>
          </>
        );
        dispatch(GetGelleryEventList());
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/* -------------------------------------AddGalleryPhoto------------------------------------- */
export const AddGalleryPhoto = (file, galleryTitle, sDescription, Category) => {
  const token = GetToken();
  const formData = new FormData();
  formData.append("file", file);
  const loginData = JSON.parse(localStorage.getItem("user"));

  var Data = new FormData();
  var fileData = new File([file], file.name, { type: "application/json" });
  Data.append("file", fileData);
  Data.append("Name", loginData?.Data.Table[0].Name);
  Data.append("UserId", loginData?.Data.Table[0].UserId);
  Data.append("Title", galleryTitle);
  Data.append("ShortDescription", sDescription);
  Data.append("CategoryId", Category);

  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Affiliate/GelleryImageUpload`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        toast.success(
          <>
            <span>Data Saved Successfully!!!</span>
          </>
        );
        dispatch(GetGelleryEventList());
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- GetDocumentList---------------------*/
export const GetDocumentList = () => {
  const token = GetToken();
  const loginData = JSON.parse(localStorage.getItem("user"));
  const Data = {
    UserId: loginData?.Data.Table[0].UserId,
    DocRefValue: 26,
    _DocumentRef: "Affiliate",
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Documents/GetDocumentListByDocRefValueAffiliate`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: actionTypes.GET_DOCUMENT_LIST,
          documentList: response.data,
          webConfigPreloader: false,
        });
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- AddDocument ---------------------*/
export const AddDocumentImageUpload = (
  file,
  docCheckListId,
  DocumentRef,
  DocRefValue
) => {
  const loginData = JSON.parse(localStorage.getItem("user"));
  const token = GetToken();
  const Data = new FormData();
  var fileData = new File([file], file.name, { type: "application/json" });
  Data.append("file", fileData);
  Data.append("DocumentCheckListId", docCheckListId);
  Data.append("DocumentRef", DocumentRef);
  Data.append("DocRefValue", DocRefValue);
  Data.append("UserId", loginData?.Data.Table[0].UserId);
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Documents/DocumentImageUpload`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        toast.success(
          <>
            <span>Data Saved Successfully!!!</span>
          </>
        );
        dispatch(GetDocumentList());
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- DocumentDeleteFile  ---------------------*/
export const DocumentDeleteFile = (DocFilePathId) => {
  const token = GetToken();
  const Data = {
    DocFilePathId: DocFilePathId,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Documents/DocumentDeleteFile`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        toast.success(
          <>
            <span>Document Deleted Successfully!!!</span>
          </>
        );
        dispatch(GetDocumentList());
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};

/*-------------------------- GetRevenueShare---------------------*/
export const GetRevenueShare = () => {
  const loginData = JSON.parse(localStorage.getItem("user"));
  const token = GetToken();
  const Data = {
    UserId: loginData?.Data.Table[0].UserId,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Affiliate/GetAffiliateDoctorDataCommision`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: actionTypes.GET_REVENUE_SHARE,
          revenueShare: response.data,
          webConfigPreloader: false,
        });
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- GetBillingPlan---------------------*/
export const GetBillingPlanHistory = () => {
  const loginData = JSON.parse(localStorage.getItem("user"));
  const token = GetToken();
  const Data = {
    UserId: loginData?.Data.Table[0].UserId,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Affiliate/GetAffiliateBillingHistory`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: actionTypes.GET_BILLING_PLAN,
          billingDetail: response.data,
          webConfigPreloader: false,
        });
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- GetAccountStatus----------------------*/
export const GetAccountStatus = () => {
  const loginData = JSON.parse(localStorage.getItem("user"));
  const token = GetToken();
  const Data = {
    affiliateDoctorUserId: loginData?.Data.Table[0].UserId,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Affiliate/affiliateStatusSuccId`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: actionTypes.GET_ACCOUNT_STATUS,
          accountStatus: response.data,
          webConfigPreloader: false,
        });
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- GetTestimonialText----------------------*/
export const GetTestimonialText = () => {
  const token = GetToken();
  const loginData = JSON.parse(localStorage.getItem("user"));
  const Data = {
    StartRow: 0,
    PageSize: 26,
    SearchText: "",
    TestimonialId: "",
    _TestimonialType: "Patients",
    AffiliateDoctorUserID: loginData?.Data.Table[0].UserId,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Affiliate/GetTestimonialsAllList`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: actionTypes.GET_TESTIMONIAL_TEXT,
          testimonialText: response.data,
          webConfigPreloader: false,
        });
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- AddNewTestimonial----------------------*/
export const AddNewTestimonial = (
  TestTextTitle,
  TestTextDesc,
  TestTextWrittenBy
) => {
  const loginData = JSON.parse(localStorage.getItem("user"));
  const token = GetToken();
  const Data = {
    TestimonialId: 0,
    AffiliateDoctorUserId: loginData?.Data.Table[0].UserId,
    Title: TestTextTitle,
    SDescription: TestTextDesc,
    WrittenBy: TestTextWrittenBy,
    UserId: loginData?.Data.Table[0].UserId,
    LDescription: "",
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Testimonial/AffiliateTestimonialListSave`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        toast.success(
          <>
            <span>Data Saved Successfully!!!</span>
          </>
        );
        dispatch(GetTestimonialText());
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- AffiliateSuccessstoriesListSave----------------------*/
export const AddNewVideoTestimonial = (VideoTitle, VideoUrl) => {
  const loginData = JSON.parse(localStorage.getItem("user"));
  const token = GetToken();
  const Data = {
    AffiliateDoctorUserId: loginData?.Data.Table[0].UserId,
    Title: VideoTitle,
    FilePath: VideoUrl,
    UserId: loginData?.Data.Table[0].UserId,
    WrittenBy: VideoTitle,
    TestimonialId: 0,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Testimonial/AffiliateSuccessstoriesListSave`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        toast.success(
          <>
            <span>Data Saved Successfully!!!</span>
          </>
        );
        dispatch(GetTestimonialVideo());
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- AffiliateTestimonialListUpdate----------------------*/
export const AffiliateTestimonialListUpdate = (
  testId,
  TestTextTitle,
  TestTextDesc,
  TestTextWrittenBy
) => {
  const loginData = JSON.parse(localStorage.getItem("user"));
  const token = GetToken();
  const Data = {
    TestimonialId: testId,
    AffiliateDoctorUserId: loginData?.Data.Table[0].UserId,
    Title: TestTextTitle,
    SDescription: TestTextDesc,
    WrittenBy: TestTextWrittenBy,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Testimonial/AffiliateTestimonialListUpdate`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        toast.success(
          <>
            <span>Data Saved Successfully!!!</span>
          </>
        );
        dispatch(GetTestimonialText());
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- AffiliateVideoTestimonialListUpdate----------------------*/
export const AffiliateVideoTestimonialListUpdate = (
  testId,
  VideoTitle,
  VideoUrl
) => {
  const loginData = JSON.parse(localStorage.getItem("user"));
  const token = GetToken();
  const Data = {
    TestimonialId: testId,
    AffiliateDoctorUserId: loginData?.Data.Table[0].UserId,
    Title: VideoTitle,
    WrittenBy: "TEST",
    FilePath: VideoUrl,
    CreatedBy: loginData?.Data.Table[0].UserId,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Testimonial/AffiliateVideoTestimonialListUpdate`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        toast.success(
          <>
            <span>Data Saved Successfully!!!</span>
          </>
        );
        dispatch(GetTestimonialVideo());
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- EditTestimonialByID----------------------*/
export const EditTestimonialByID = (testId) => {
  const token = GetToken();
  const Data = {
    TestimonialId: testId,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Testimonial/GetManageTestimonialsListById`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: actionTypes.EDIT_TESTIMONIAL_DATA,
          editTestData: response.data,
          webConfigPreloader: false,
        });
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- DeleteTestimonialtextbyID----------------------*/
export const DeleteTestimonialtextbyID = (testId) => {
  const token = GetToken();
  const Data = {
    TestimonialId: testId,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Testimonial/TestimonialsDelete`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        toast.success(
          <>
            <span>Data Deleted Successfully!!!</span>
          </>
        );
        dispatch(GetTestimonialText());
        dispatch(GetTestimonialVideo());
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- GetTestimonialVideo----------------------*/
export const GetTestimonialVideo = () => {
  const token = GetToken();
  const loginData = JSON.parse(localStorage.getItem("user"));
  const Data = {
    StartRow: 0,
    PageSize: 26,
    SearchText: "",
    TestimonialId: "",
    _TestimonialType: "Patients",
    AffiliateDoctorUserID: loginData?.Data.Table[0].UserId,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Affiliate/AFFILIATEGetSuccessstoriesAllList`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: actionTypes.GET_TESTIMONIAL_VIDEO,
          testimonialVideo: response.data,
          webConfigPreloader: false,
        });
        window.dispatchEvent(new Event("resize"));
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};

/*-------------------------- GetIsWebsiteDomainRegistered----------------------*/
export const GetIsWebsiteDomainRegistered = () => {
  const loginData = JSON.parse(localStorage.getItem("user"));
  const token = GetToken();
  const Data = {
    UserId: loginData?.Data.Table[0].UserId,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Affiliate/GetIsWebsiteDomainRegistered`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: actionTypes.GET_WEBSITE_DOMAIN,
          webDomain: response.data,
          webConfigPreloader: false,
        });
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- DomainCheckAvailability----------------------*/
export const DomainCheckAvailability = (DomainName) => {
  const loginData = JSON.parse(localStorage.getItem("user"));
  const token = GetToken();
  const Data = {
    UserId: loginData?.Data.Table[0].UserId,
    UserName: loginData?.Data.Table[0].Name,
    DomainName: DomainName,
    WrittenPrescription: "",
    CampaignCode: "",
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Affiliate/AffiliateDomainCheckAvailability`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: actionTypes.DOMAIN_CHECK_AVAILABILITY,
          DomainCheck: response.data,
          webConfigPreloader: false,
        });
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- AffiliateDomainRegistration----------------------*/
export const AffiliateDomainRegistration = (DomainName) => {
  const token = GetToken();
  const loginData = JSON.parse(localStorage.getItem("user"));
  const Data = {
    UserId: loginData?.Data.Table[0].UserId,
    UserName: loginData?.Data.Table[0].Name,
    DomainName: DomainName,
    WrittenPrescription: "",
    CampaignCode: "",
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Affiliate/AffiliateDomainRegistration`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        toast.success(
          <>
            <span>Data Deleted Successfully!!!</span>
          </>
        );

        dispatch({
          type: actionTypes.DOMAIN_REGISTRATION,
          DomainRegistration: response.data,
          webConfigPreloader: false,
        });
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*-------------------------- GetdoctorDetailPreviewPage----------------------*/
export const GetdoctorDetailPreviewPage = (id) => {
  const token = GetToken();
  const Data = {
    UserId: id,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api//user/GetdoctorDetail`,
        Data,
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: actionTypes.GET_PREVIEW_PAGE_DATA,
          PreviewData: response.data,
          webConfigPreloader: false,
        });
      })
      .catch((err) => {
        Authcontext(err.response);
      });
  };
};
/*--------------------------------set preloader------------------------ */
export const setPreloaderWebConfigAction = () => {
  return {
    type: actionTypes.SET_PRE_LOADER_WEB_CONFIG,
    webConfigPreloader: true,
  };
};

export const reset = (RESET) => {
  return {
    type: RESET,
  };
};
