export const actionTypes = {
  SET_PRE_LOADER: "SET_PRE_LOADER",
  GET_TRANSACTION_LIST: "GET_TRANSACTION_LIST",
  GET_TRANSACTION_LIST_PENDING: "GET_TRANSACTION_LIST_PENDING",
  GET_TRANSACTION_LIST_SUCCESS: "GET_TRANSACTION_LIST_SUCCESS",
  GET_TRANSACTION_LIST_FAILED: "GET_TRANSACTION_LIST_FAILED",
  GET_TRANSACTION_LIST_CANCELLED: "GET_TRANSACTION_LIST_CANCELLED",
  GET_TRANSACTION_LIST_PENDINGVERIFICATION:
    "GET_TRANSACTION_LIST_PENDINGVERIFICATION",
  GET_TRANSACTION_FAMILYMEMBER_LIST: "GET_TRANSACTION_FAMILYMEMBER_LIST",
};
const INITIAL_STATE = {
  TransactionList: [],
  TransactionListPendingVerification: [],
  TransactionListCancelled: [],
  TransactionListFailed: [],
  TransactionListSuccess: [],
  TransactionListPending: [],
  TransactionFamilyMemberList: [],
  Preloadertransaction: false,
};

const TransactionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // -----------------------------------SET_PRE_LOADER------------------------------------
    case actionTypes.SET_PRE_LOADER:
      return {
        ...state,
        Preloadertransaction: action.Preloadertransaction,
      };
    // ----------------------------------GET_TRANSACTION_LIST------------------------------------
    case actionTypes.GET_TRANSACTION_LIST:
      return {
        ...state,
        TransactionList: action.TransactionList,
        Preloadertransaction: action.Preloadertransaction,
      };
    // ----------------------------------GET_TRANSACTION_LIST_PENDING------------------------------------
    case actionTypes.GET_TRANSACTION_LIST_PENDING:
      return {
        ...state,
        TransactionListPending: action.TransactionListPending,
        Preloadertransaction: action.Preloadertransaction,
      };
    // ----------------------------------GET_TRANSACTION_LIST_SUCCESS------------------------------------
    case actionTypes.GET_TRANSACTION_LIST_SUCCESS:
      return {
        ...state,
        TransactionListSuccess: action.TransactionListSuccess,
        Preloadertransaction: action.Preloadertransaction,
      };
    // ----------------------------------GET_TRANSACTION_LIST_FAILED------------------------------------
    case actionTypes.GET_TRANSACTION_LIST_FAILED:
      return {
        ...state,
        TransactionListFailed: action.TransactionListFailed,
        Preloadertransaction: action.Preloadertransaction,
      };
    // ----------------------------------GET_TRANSACTION_LIST_CANCELLED------------------------------------
    case actionTypes.GET_TRANSACTION_LIST_CANCELLED:
      return {
        ...state,
        TransactionListCancelled: action.TransactionListCancelled,
        Preloadertransaction: action.Preloadertransaction,
      };
    // ----------------------------------GET_TRANSACTION_LIST_PENDINGVERIFICATION------------------------------------
    case actionTypes.GET_TRANSACTION_LIST_PENDINGVERIFICATION:
      return {
        ...state,
        TransactionListPendingVerification:
          action.TransactionListPendingVerification,
        Preloadertransaction: action.Preloadertransaction,
      };
    // ----------------------------------GET_TRANSACTION_FAMILYMEMBER_LIST------------------------------------
    case actionTypes.GET_TRANSACTION_FAMILYMEMBER_LIST:
      return {
        ...state,
        TransactionFamilyMemberList: action.TransactionFamilyMemberList,
        Preloadertransaction: action.Preloadertransaction,
      };

    default:
      return state;
  }
};

export default TransactionReducer;
