export const actionTypes = {
  SET_PRESCRIPTION: "SET_PRESCRIPTION",
  SET_PRESCRIPTIONPREVIEW: "SET_PRESCRIPTIONPREVIEW",
  SET_PRESCRIPTIONDELIVERY: "SET_PRESCRIPTIONDELIVERY",
  SET_PRESCRIPTIONPRINT: "SET_PRESCRIPTIONPRINT",
  SET_PRIORITYLIST: "SET_PRIORITYLIST",
  SET_SENDDELIVERY: "SET_SENDDELIVERY",
  SET_PRESCRIPTIONPRELOADER: "SET_PRESCRIPTIONPRELOADER",
};

const INITIAL_STATE = {
  PriscriptionRepeate: [],
  Priscriptionpreview: [],
  Priscriptiondelivery: [],
  PriscriptionPrint: [],
  Prioritylist: [],
  SendDelivery: [],
  PriscriptionPreloader: false,
};

const PrescriptionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SET_PRESCRIPTION:
      return {
        ...state,
        PriscriptionRepeate: action.PriscriptionRepeate,
      };
    case actionTypes.SET_PRESCRIPTIONPREVIEW:
      return {
        ...state,
        Priscriptionpreview: action.Priscriptionpreview,
      };
    case actionTypes.SET_PRESCRIPTIONDELIVERY:
      return {
        ...state,
        Priscriptiondelivery: action.Priscriptiondelivery,
      };
    case actionTypes.SET_PRIORITYLIST:
      return {
        ...state,
        Prioritylist: action.Prioritylist,
      };
    case actionTypes.SET_PRESCRIPTIONPRINT:
      return {
        ...state,
        PriscriptionPrint: action.PriscriptionPrint,
      };
    case actionTypes.SET_SENDDELIVERY:
      return {
        ...state,
        SendDelivery: action.SendDelivery,
        PriscriptionPreloader: action.PriscriptionPreloader,
      };

    case actionTypes.SET_PRESCRIPTIONPRELOADER:
      return {
        ...state,
        PriscriptionPreloader: action.PriscriptionPreloader,
      };
    default:
      return state;
  }
};

export default PrescriptionReducer;
