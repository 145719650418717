import React , { useState , useEffect } from "react";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { FaPlusCircle } from "react-icons/fa";
import { FiMenu } from "react-icons/fi";

import { useRef } from "react";
import UseOutsideClick from "./OutsideClick";
// import {IoClose} from "react-icons/io5"
import { Button } from "reactstrap";
import "../css/Layout.css";
import { connect } from "react-redux";


const Header = ({PreviewData}) => {
  const [Menu, setMenu] = useState(false);
  const [Toggle, setToggle] = useState(false);
  const [DoctorData, setDoctorData] = useState();
  const ref = useRef();
  const ref1 = useRef();
  UseOutsideClick(ref, () => {
    if (Menu) {
      setMenu(false);
    }
  });
  UseOutsideClick(ref1, () => {
    if (Toggle) {
      setToggle(false);
    }
  });
  useEffect(() => {
  setDoctorData(PreviewData && PreviewData)
  }, [PreviewData])
  return (<>
    {DoctorData && <div className="fixed-top w-100 bg-white z-10 newcolor textfamily border-bottom">
      <nav className={`py-2`}>
        <div className="container mx-auto d-flex justify-content-between">
          <div className="d-flex align-items-center my-2 my-xl-4" style={{maxWidth: "260px",}}>
            <img className="color" src={DoctorData?.Table[0]?.lOGO} width="60" height="60" alt="logo" />
            <h3 className="mx-2 ">{DoctorData?.Table6[0]?.clinicname}</h3>
          </div>
          <h2 className="d-xl-none">
            <FiMenu className="mt-3 " onClick={() => setMenu(!Menu)} />
          </h2>
          {/* for sidebar */}
          {Menu && (
            <div
              className="position-fixed top-0 start-0 bottom-0 d-xl-none shadow-lg bg-white"
              ref={ref}
            >
              <ul className="list-unstyled color textfamily mt-4">
                <li className="d-flex px-3 py-2 newcolor align-items-center">
                  <FaPlusCircle style={{ fontSize: 35 }} className="color" />
                  <h3 className="mx-2  text-2xl">{DoctorData?.Table6[0]?.clinicname}</h3>
                </li>
                <li className="pt-4 px-4 font-size" onClick={() => setMenu(false)}>
                  Home
                </li>
                <li onClick={() => setMenu(false)} className="py-2 px-4 font-size">
                  About
                </li>
                <li className="py-2 px-4 font-size" onClick={() => setMenu(false)}>
                  Testimonial
                </li>
                <li onClick={() => setMenu(false)} className="py-2 px-4 font-size">
                  Homeopathy
                </li>
                {/* <Link to='/Covid-information' onClick={() => window.scrollTo(0,0)}><li className="py-5" onClick={() => setMenu(false)}> Covid-19 Information</li></Link> */}
                <li onClick={() => setMenu(false)} className="py-2 px-4 font-size">
                  Health Plans
                </li>
                <li className="py-2 px-4 font-size" onClick={() => setMenu(false)}>
                  Gallery
                </li>
                <li onClick={() => setMenu(false)} className="py-2 px-4 font-size">
                  FAQ
                </li>
                <li className="py-2 px-4 font-size" onClick={() => setMenu(false)}>
                  Contact Us
                </li>
                <div className="my-3 px-4">
                  <Button className="back" onClick={() => setMenu(false)}>
                    Start Treatment
                  </Button>
                </div>
                <div className="px-4">
                  <Button className="px-5 back" onClick={() => setMenu(false)}>
                    Login
                  </Button>
                </div>
                <li className="flex align-items-center pt-5 px-4 font-size ">
                  <BsFillTelephoneFill className="color  mr-1" />
                  <span className="mx-2 text-black ">{DoctorData?.Table[0]?.clinicname}</span>
                </li>
                <li className="flex align-items-center pb-5 px-4 font-size mt-3">
                  <MdEmail className="color mr-1" />
                  <span className="mx-2 text-black ">Email Me</span>
                </li>
              </ul>
            </div>
          )}

          <div className="d-none d-xl-block">
            <div className="d-flex flex-column">
              <ul className="d-flex justify-content-end align-items-center mb-3 list-unstyled">
                <li className="mx-2 mt-2 newtext">Global</li>
                <li className="d-flex mx-3 mt-2">
                  <BsFillTelephoneFill className="color mt-1" style={{width : "16px" , height: "16px"}} />
                  <span className="mx-2  newtext">{DoctorData?.Table[0]?.MobileNo}</span>
                </li>
                <li
                  className="d-flex mx-2 mt-2 hover:cursor-pointer"
                  onClick={() => setToggle(true)}
                >
                  <MdEmail className="color mt-1" style={{width : "16px" , height: "16px"}}/>
                  <span className="mx-2 newtext">Email Me</span>
                </li>
                <li>
                  <div>
                    <Button className="back px-4 py-2">Start Treatment</Button>
                  </div>
                </li>
                <li>
                  <div className="mx-3">
                    <Button className="back px-4 py-2">Login</Button>
                  </div>
                </li>
              </ul>
              <ul className="d-flex flex-row newcolor list-unstyled">
                <li className="mx-3 texthover newtext">Home</li>
                <li className="mx-3 texthover newtext">About</li>
                <li className="mx-3 texthover newtext">Testimonial</li>
                <li className="mx-3 texthover newtext">HomeoPathy</li>
                <li className="mx-3 texthover newtext">Health Plans</li>
                <li className="mx-3 texthover newtext">Gallery</li>
                <li className="mx-3 texthover newtext">FAQs</li>
                <li className="mx-3 texthover newtext">Contact Us</li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    PreviewData: state.WebConfig.PreviewData.Data?.Data,
  };
};
export default connect(mapStateToProps)(Header);
