export const actionTypes = {
  GET_All_LIST: "GET_All_LIST",
  GET_LEAD_LIST: "GET_LEAD_LIST",
  GET_PATIENT_LIST: "GET_PATIENT_LIST",
  VIEWED_LOG_DETAIL: "VIEWED_LOG_DETAIL",
  GET_CAMPAIGN_NAME_GET_LIST: "GET_CAMPAIGN_NAME_GET_LIST",
  GET_SUB_DISEASES_BY_USERID: "GET_SUB_DISEASES_BY_USERID",
  SUB_DISEASES_DELETE: "SUB_DISEASES_DELETE",
  LEAD_STATUS_GET_LIST: "LEAD_STATUS_GET_LIST",
  GET_CONTACT_CALL_STATUS_LIST: "GET_CONTACT_CALL_STATUS_LIST",
  CONTECT_QUALIFICATION_GET_LIST: "CONTECT_QUALIFICATION_GET_LIST",
  GET_CONTACT_CAMPAIGN_DETAIL_BY_USER_ID:
    "GET_CONTACT_CAMPAIGN_DETAIL_BY_USER_ID",
  MESSAGE_CHAT_TEMPLATE_GET_LIST: "MESSAGE_CHAT_TEMPLATE_GET_LIST",
  MESSAGE_TYPE_GET_LIST: "MESSAGE_TYPE_GET_LIST",
  GET_MESSAGE_TIME_LINE_LIST: "GET_MESSAGE_TIME_LINE_LIST",
  GET_CONTACT_CAMPAIGN_CODE_BY_USER_ID: "GET_CONTACT_CAMPAIGN_CODE_BY_USER_ID",
  CONTECT_STATUS_GET_DETAIL_BY_ID: "CONTECT_STATUS_GET_DETAIL_BY_ID",
  SET_PRELOADER_PATIENT_LIST: "SET_PRELOADER_PATIENT_LIST",
  PATIENT_STATUS_GET_LIST: "PATIENT_STATUS_GET_LIST",
  RESET_CONTACT_CAMPAIGN_CODE_BY_USER_ID : 'RESET_CONTACT_CAMPAIGN_CODE_BY_USER_ID'
};

const INITIAL_STATE = {
  PreloaderPatientList: false,
  patientlist: [],
  LeadList: [],
  AllList: [],
  GetCampaignNameGetList: [],
  GetSubDiseasesByUser: [],
  LeadStatusGetList: [],
  GetContactCallStatusList: [],
  ContactQualificationGetList: [],
  ContactCampaignDetailByUserId: [],
  GetCampaignCodeById: [],
  ChatTemplateGetList: [],
  MessageTypeList: [],
  MessageTimeLineList: [],
  ContactStatusGetDetail: [],
  ViewByDoctor: [],
  PatientStatusGetList: [],
};

const PatientListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // -----------------------------------SET_PRELOADER_PATIENT_LIST------------------------------------
    case actionTypes.SET_PRELOADER_PATIENT_LIST:
      return {
        ...state,
        PreloaderPatientList: action.PreloaderPatientList,
      };
    // -----------------------------------get all list------------------------------------
    case actionTypes.GET_All_LIST:
      return {
        ...state,
        AllList: action.AllList,
        PreloaderPatientList: action.PreloaderPatientList,
      };
    // -----------------------------------get LEAD  list------------------------------------
    case actionTypes.GET_LEAD_LIST:
      return {
        ...state,
        LeadList: action.LeadList,
        PreloaderPatientList: action.PreloaderPatientList,
      };
    case actionTypes.PATIENT_STATUS_GET_LIST:
      return {
        ...state,
        PatientStatusGetList: action.PatientStatusGetList,
      };
    // -----------------------------------get patient------------------------------------
    case actionTypes.GET_PATIENT_LIST:
      return {
        ...state,
        patientlist: action.patientlist,
        PreloaderPatientList: action.PreloaderPatientList,
      };
    // -----------------------------------VIEWED_LOG_DETAIL------------------------------------
    case actionTypes.VIEWED_LOG_DETAIL:
      localStorage.setItem(
        "ViewByDoctor",
        JSON.stringify(action.ViewByDoctor.Data)
      );
      return {
        ...state,
        ViewByDoctor: action.ViewByDoctor,
      };
    //--------------------------------GET_CAMPAIGN_NAME_GET_LIST--------------------------
    case actionTypes.GET_CAMPAIGN_NAME_GET_LIST:
      localStorage.setItem(
        "GetCampaignNameGetList",
        JSON.stringify(action.GetCampaignNameGetList.Data)
      );
      return {
        ...state,
        GetCampaignNameGetList: action.GetCampaignNameGetList,
      };
    //--------------------------------GetSubDiseasesByUserid--------------------------
    case actionTypes.GET_SUB_DISEASES_BY_USERID:
      localStorage.setItem(
        "GetSubDiseasesByUser",
        JSON.stringify(action.GetSubDiseasesByUser.Data)
      );
      return {
        ...state,
        GetSubDiseasesByUser: action.GetSubDiseasesByUser,
        PreloaderPatientList: action.PreloaderPatientList,
      };
    //--------------------------------LEAD_STATUS_GET_LIST--------------------------
    case actionTypes.LEAD_STATUS_GET_LIST:
      localStorage.setItem(
        "LeadStatusGetList",
        JSON.stringify(action.LeadStatusGetList.Data)
      );
      return {
        ...state,
        LeadStatusGetList: action.LeadStatusGetList,
      };
    //--------------------------------GetContactCallStatusList--------------------------
    case actionTypes.GET_CONTACT_CALL_STATUS_LIST:
      localStorage.setItem(
        "GetContactCallStatusList",
        JSON.stringify(action.GetContactCallStatusList.Data)
      );
      return {
        ...state,
        GetContactCallStatusList: action.GetContactCallStatusList,
      };
    //--------------------------------ContactQualificationGetList--------------------------

    case actionTypes.CONTECT_QUALIFICATION_GET_LIST:
      localStorage.setItem(
        "ContactQualificationGetList",
        JSON.stringify(action.ContactQualificationGetList.Data)
      );
      return {
        ...state,
        ContactQualificationGetList: action.ContactQualificationGetList,
      };
    //--------------------------------CONTECT_STATUS_GET_DETAIL_BY_ID--------------------------

    case actionTypes.CONTECT_STATUS_GET_DETAIL_BY_ID:
      localStorage.setItem(
        "ContactStatusGetDetail",
        JSON.stringify(action.ContactStatusGetDetail.Data)
      );
      return {
        ...state,
        ContactStatusGetDetail: action.ContactStatusGetDetail,
      };
    //--------------------------------GetCampaignCodeById--------------------------

    case actionTypes.GET_CONTACT_CAMPAIGN_CODE_BY_USER_ID:
      localStorage.setItem(
        "GetCampaignCodeById",
        JSON.stringify(action.GetCampaignCodeById.Data)
      );
      return {
        ...state,
        GetCampaignCodeById: action.GetCampaignCodeById,
      };
    case actionTypes.RESET_CONTACT_CAMPAIGN_CODE_BY_USER_ID:
      return {
        ...state,
        GetCampaignCodeById: INITIAL_STATE.GetCampaignCodeById,
      };
    //--------------------------------ContactCampaignDetailByUserId--------------------------

    case actionTypes.GET_CONTACT_CAMPAIGN_DETAIL_BY_USER_ID:
      localStorage.setItem(
        "ContactCampaignDetailByUserId",
        JSON.stringify(action.ContactCampaignDetailByUserId.Data)
      );
      return {
        ...state,
        ContactCampaignDetailByUserId: action.ContactCampaignDetailByUserId,
      };
    //--------------------------------ChatTemplateGetList--------------------------

    case actionTypes.MESSAGE_CHAT_TEMPLATE_GET_LIST:
      localStorage.setItem(
        "ChatTemplateGetList",
        JSON.stringify(action.ChatTemplateGetList.Data)
      );
      return {
        ...state,
        ChatTemplateGetList: action.ChatTemplateGetList,
      };
    //--------------------------------ChatTemplateGetList--------------------------

    case actionTypes.GET_MESSAGE_TIME_LINE_LIST:
      localStorage.setItem(
        "MessageTimeLineList",
        JSON.stringify(action.MessageTimeLineList.Data)
      );
      return {
        ...state,
        MessageTimeLineList: action.MessageTimeLineList,
      };
    //--------------------------------MessageTypeList--------------------------

    case actionTypes.MESSAGE_TYPE_GET_LIST:
      localStorage.setItem(
        "MessageTypeList",
        JSON.stringify(action.MessageTypeList.Data)
      );
      return {
        ...state,
        MessageTypeList: action.MessageTypeList,
      };

    default:
      return state;
  }
};

export default PatientListReducer;
