import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const ProLoaderShow = () => {
    return (<div className="login-preloader" style={{zIndex : 10000}} >
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
        </Box>
    </div>

    );
}

export default ProLoaderShow;