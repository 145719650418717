import React , { useState , useEffect } from "react";
import facebook from "../images/demo_img/facebook.svg"
import linkedin from "../images/demo_img/linkedin.svg"
// import twitter from "../images/demo_img/twitter.svg"
import youtube from "../images/demo_img/youtube.svg"
import telegram  from "../images/demo_img/Telegram_icon.svg"
import Instagram from "../images/demo_img/instagram_icon.svg"
import Skype from "../images/demo_img/skype_icon.svg"
import Whatsapp  from "../images/demo_img/whatsapp_icon.svg"
import location from "../images/demo_img/Newlocation.png"
import Calling from "../images/demo_img/telephone-call.png"
import mail from "../images/demo_img/email.png"
import website from "../images/demo_img/click.png"
import twitter from "../images/social/twitter.svg";

import {Row,Col} from 'reactstrap';
import { connect } from "react-redux";

const ContactUs = ({PreviewData}) => {
    const [DoctorData, setDoctorData] = useState();
  
    useEffect(() => {
      setDoctorData(PreviewData && PreviewData?.Data)
    
      }, [PreviewData])
    
  return (<>
  {DoctorData &&
    <div className='container mx-auto mt-5 d-flex flex-row textfamily'>
      <Row className='mx-2 w-100'>
        <Col md='12' lg='6'>
     <div className='h-100'>
         <div>
         <h2 className='mb-4 text-left'>Directions</h2>
         <iframe src={DoctorData?.Table6[0]?.MapUrl} className='w-100' style={{height : 280}} title='new'></iframe>
         </div>
     </div>
     </Col>
     <Col md='12' lg='6'>
     <div>
        <div className='mt-4 mt-lg-0'>
     <h2 className='mb-4 mx-lg-4'>Contact Us</h2>
        <ul className='list-unstyled mx-lg-4 newcolor'>
            <li className='d-flex'><img src={location} alt='new' className=' iconc'/><span className='mx-3'>{DoctorData?.Table[0]?.address} <span> {DoctorData?.Table[0]?.City} , </span> {DoctorData?.Table[0]?.CountryName}.</span></li>
            <li className='d-flex align-items-center mt-4'><img src={Calling} alt='new'/><span className='mx-3'>{DoctorData?.Table[0].MobileNo}</span></li>
            <li className='d-flex align-items-center mt-4'><img src={mail} alt='new'/><span className='mx-3 cursor-pointer text-primary'>{DoctorData?.Table[0].EmailId1}</span></li>
            <li className='d-flex align-items-center mt-4'><img src={website} alt='new'/><a href="#" className='mx-3 text-primary'>https://{DoctorData?.Table[0].DomainName ? `${DoctorData[0].DomainName.includes("www.") ?  `${DoctorData[0].DomainName}` : `www.${DoctorData[0].DomainName}`}` : "docsoluat.welcomecure.com"}</a></li>
        </ul>
        <ul className='d-flex mx-lg-4 list-unstyled mt-4'>
            <li className='mx-1'><a href={"#"} ><img src={Instagram} alt='insta'/></a></li>
            <li className='mx-1'><a href={"#"} ><img src={twitter} alt='twi'/></a></li>
            <li className='mx-1'><a href={"#"} ><img src={linkedin} alt='link'/></a></li>
            <li className='mx-1'><a href={"#"} ><img src={telegram} alt='pin'/></a></li>
            <li className='mx-1'><a href={"#"} ><img src={youtube} alt='you'></img></a></li>
            <li className='mx-1'><a href={"#"} ><img src={Skype} alt='you'></img></a></li>
            <li className='mx-1'><a href={"#"} ><img src={facebook} alt='facebook'/></a></li>
            <li className='mx-1'><a href={"#"} ><img src={Whatsapp} alt='you'></img></a></li>
        </ul>
        </div>
     </div>
     </Col>
     </Row>
    </div>
  }</>
    
  )
}
const mapStateToProps = (state) => {
  return {
    PreviewData: state.WebConfig.PreviewData.Data,
  };
};
export default connect(mapStateToProps)(ContactUs);