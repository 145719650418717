import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom'
import {
	settoken,
	setlogin,
	setPreloaderTrueAuth,
	ForgotPassword,
	reset
} from '../../store/actions/AuthActions';
import {  Button,  Modal, ModalBody } from "react-bootstrap";
import {
	Form,
	FormGroup,
	Input,
	Label,
} from "reactstrap"
// image
//import logo from "../../images/logo-full.png";
import loginbg from "../../images/pic1.png";
import logo_full from "../../images/logo-full.png";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProLoaderShow from '../components/ProLoaderShow';
import Lottie from 'react-lottie';
import animationData  from '../../images/lottie_login.json';
import { actionTypes } from '../../store/reducers/login-user-Reducer';
function Login(props) {

	const [RegNo, setRegNo] = useState('');
	const [RegisterNo, setRegisterNo] = useState('');
	const [leadManageModal, setLeadManageModal] = useState(false);
	let errorsObj = { RegNo: '', password: '' };
	const [errors, setErrors] = useState(errorsObj);
	const [password, setPassword] = useState('');

	const dispatch = useDispatch();

	function onLogin(e) {
		e.preventDefault();
		let error = false;
		const errorObj = { ...errorsObj };
		if (RegNo === '') {
			errorObj.RegNo = 'RegNo is Required';
			error = true;
		}
		if (password === '') {
			errorObj.password = 'Password is Required';
			error = true;
		}
		setErrors(errorObj);
		if (error) {
			return;
		}
		// dispatch(loadingToggleAction(true));	
		// dispatch(loginAction(RegNo, password, props.history));
		dispatch(setPreloaderTrueAuth())

		dispatch(setlogin(RegNo, password, props.history));
		// localStorage.setItem('loginpass' , password)
	}

	useEffect(() => {
		dispatch(settoken())
	}, []);


	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
	  };

	  const GetPassword = (e) => {
       e?.preventDefault();
	   dispatch(ForgotPassword(RegisterNo))
	   dispatch(setPreloaderTrueAuth())
	   setRegisterNo('')
	  }

	//   console.log(props.forgotpassword.StatusCode,'forgotpassword')
	return (
		<>
			<ToastContainer />
			{props.Preloader && <ProLoaderShow />}
			<div className="authincation d-flex flex-column flex-lg-row flex-column-fluid">
				<div className="login-aside text-center  d-flex flex-column flex-row-auto">
					<div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
						<div className="text-center mb-4 pt-5">
							<img className="logo-abbr" src={logo_full} />
						</div>
						<h3 className="mb-4">Welcome back!</h3>
						<p >Ensure the <span className='fs-3 font-bold'>Health &#38; Wellbeing </span> <br />of your  <span className='fs-3 font-bold'>Family</span> with <span className='fs-1 font-bold' style={{ color : "#F39F08"}}>Homeopathy</span></p>
					</div>
					{/* <div className="aside-image" style={{ backgroundImage: "url(" + loginbg + ")" }}></div> */}
					<div  className="aside-image mx-auto" style={{maxWidth: 500 , maxHeight : 500}}>
					<Lottie options={defaultOptions} isClickToPauseDisabled={true} 
 style={{cursor : 'auto'}}/>
					</div>
				
				
				</div>
				<div className="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
					<div className="d-flex justify-content-center h-100 ">
						<div className="authincation-content style-2">
							<div className="row no-gutters">
								<div className="col-xl-12 tab-content">
									<div id="sign-in" className="auth-form   form-validation">
										{/* {props.errorMessage && (
										<div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
											{props.errorMessage}
										</div>
									)}
									{props.successMessage && (
										<div className='bg-green-300 text-green-900 border border-green-900 p-1 my-2'>
											{props.successMessage}
										</div>
									)} */}
										<Form onSubmit={onLogin} className="form-validate">
											<h3 className="text-center mb-4 text-black">Sign In to WelcomeCure</h3>
											<div className="form-group mb-3">
												<Label className="mb-1" htmlFor="val-RegNo"><strong>Registration Number <span className='text-danger'>*</span></strong></Label>
												<div>
													<Input type="RegNo" className="form-control"
														value={RegNo}
														onChange={(e) => setRegNo(e.target.value)}
														placeholder="Registration Number"
														required
													/>
												</div>
												{errors.RegNo && <div className="text-danger fs-12">{errors.RegNo}</div>}
											</div>
											<div className="form-group mb-3">
												<Label className="mb-1"><strong>Password <span className='text-danger'>*</span></strong></Label>
												<Input
													type="password"
													className="form-control"
													value={password}
													placeholder="Type Your Password"
													onChange={(e) =>
														setPassword(e.target.value)
													}
													required
												/>
												{errors.password && <div className="text-danger fs-12">{errors.password}</div>}
											</div>
											<div className="form-row d-flex justify-content-between mt-4 mb-2">
												<div className="form-group mb-3 d-flex justify-content-between w-100">
													<div className="custom-control custom-checkbox ml-1">
														<input type="checkbox" className="form-check-input" id="basic_checkbox_1" />
														<label className="form-check-label" htmlFor="basic_checkbox_1">Remember Me</label>
													</div>
													<div>
														<p className='cursor-pointer' onClick={() => {setLeadManageModal(true)
					 									dispatch(reset(actionTypes.RESET_PASSWORD))
														}}><strong>Forgot Password?</strong></p>
													</div>
												</div>
											</div>
											<div className="text-center form-group mb-4">
												<button type="submit" className="btn btn-primary btn-block">
													Sign In
												</button>
											</div>
										</Form>
										{/* <div className="new-account mt-3">
											<p>Don't have an account? <Link className="text-primary" to="./page-register">Sign up</Link></p>
										</div> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal className="fade" show={leadManageModal}>
                <Modal.Header>
                  <Modal.Title>Forgot your Password?</Modal.Title>
                  <Button
                    variant=""
                    className="btn-close"
                    onClick={() => {setLeadManageModal(false)
					}}
                  >

                  </Button>
                </Modal.Header>
				{props.forgotpassword.StatusCode === 200 ? <ModalBody>
					<div className=''>
						<p>An email with a link to change the password has been sent to your registered email address. Please check your inbox and follow the instructions to complete the password reset process.</p>
					    <strong>Note : If you have not received the email, please check your Spam or Bulk E-Mail folder.</strong>
					</div>
				</ModalBody> : 
                <Form onSubmit={GetPassword}>
                <Modal.Body>
					<p><strong>WelcomeCure will send password reset instructions to the email address associated with your account. Please provide your registration number.</strong></p>
                    <FormGroup className="form-group mb-3 row">
                              <Label className="col-sm-5 d-flex align-items-center col-form-label">        
							  Registration Number  <span className='text-danger ms-1'> * </span>
                              </Label>
                              <div className="col-sm-7">
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="Lead"
                                    placeholder="Enter Registration Number"
                                    required
                                    value={RegisterNo}
                                    onChange={e => setRegisterNo(e.target.value)}
                                  />
                              </div>
                    </FormGroup>
                </Modal.Body>
                <Modal.Footer>
                  {/* <Button
                    onClick={() => {setLeadManageModal(false)
						setErrors('')
					}}
                    variant="danger light"
                  >
                    Close
                    </Button> */}
				  <Button 
					variant="primary" type='submit'>Submit</Button>
                </Modal.Footer>
                </Form>}
              </Modal>
              

		</>


	);
};

const mapStateToProps = (state) => {
	return {
		// errorMessage: state.auth.errorMessage,
		// successMessage: state.auth.successMessage,
		Preloader: state.login.Preloader,
		forgotpassword : state.login.forgotpassword
	};
};
export default withRouter(connect(mapStateToProps)(Login));
