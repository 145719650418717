export const actionTypes = {
  FILTER_CURRENT_YEAR_EARNING_REPORT: "FILTER_CURRENT_YEAR_EARNING_REPORT",
  FILTER_TODAY_EARNING_REPORT: "FILTER_TODAY_EARNING_REPORT",
  FILTER_PAST_MONTH_EARNING_REPORT: "FILTER_PAST_MONTH_EARNING_REPORT",
  FILTER_FROM_TO_EARNING: "FILTER_FROM_TO_EARNING",
};

const INITIAL_STATE = {
  FilterCurrentYearEarning: [],
};

const EarningsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // -----------------------------------FilterCurrentYearEarning------------------------------------
    case actionTypes.FILTER_CURRENT_YEAR_EARNING_REPORT:
      return {
        ...state,
        FilterCurrentYearEarning: action.FilterCurrentYearEarning,
      };
    //-----------------------------------FiltertodayEarning------------------------------------
    case actionTypes.FILTER_TODAY_EARNING_REPORT:
      return {
        ...state,
        FilterCurrentYearEarning: action.FiltertodayEarning,
      };
    //-----------------------------------FilterByMonthEarning------------------------------------
    case actionTypes.FILTER_PAST_MONTH_EARNING_REPORT:
      return {
        ...state,
        FilterCurrentYearEarning: action.FilterByMonthEarning,
      };
    //-----------------------------------FilterFromToEarning------------------------------------
    case actionTypes.FILTER_FROM_TO_EARNING:
      return {
        ...state,
        FilterCurrentYearEarning: action.FilterFromToEarning,
      };

    default:
      return state;
  }
};

export default EarningsReducer;
