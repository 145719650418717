import React from 'react'
import {FiFacebook} from "react-icons/fi"
import {FaInstagram ,FaSkype,FaWhatsapp} from "react-icons/fa"
// import {BsTwitter} from "react-icons/bs"
import {RiLinkedinFill} from "react-icons/ri"
import {BsTelegram ,BsYoutube} from "react-icons/bs"
import icon from "../images/demo_img/Welcomecure.png"
import twitter from "../images/twitter_Stroke.png"
import {Nav,NavItem} from 'reactstrap'
import '../css/Layout.css'
function Footer() {
  return (
    <div className='w-100 mt-5 textfamily newcolor'>
      <div className='backfoot py-3'>
     <div className='container mx-auto d-flex justify-content-between py-7 flex-row flex-wrap'>
    <div className='d-flex mx-2 mx-md-0'>
    <img src={icon} className="w-5 h-5 rounded-full" alt='name'></img>
    <h6 className='mx-2 pt-3 text-white'>Powered by Welcome Cure</h6>
    </div>
    <Nav className='d-flex  pt-3 mt-3 mt-md-0 mx-2 mx-md-0'>
            <NavItem className='text-white'>Find me on</NavItem>
            <NavItem className='mx-2 '><a href="#"><img src={twitter} style={{width : 15}}></img></a></NavItem>
            <NavItem className='mx-2 '><a href="#"><RiLinkedinFill style={{fontSize : 20}} className="text-white"/></a></NavItem>
            <NavItem className='mx-2 '><a href="#"><BsTelegram style={{fontSize : 20}} className="text-white"/></a></NavItem>
            <NavItem className='mx-2 '><a href="#"><BsYoutube style={{fontSize : 20}} className="text-white"/></a></NavItem>
            <NavItem className='mx-2 '><a href="#"><FaSkype style={{fontSize : 20}} className="text-white"/></a></NavItem>
            <NavItem className='mx-2 '><a href="#"><FiFacebook style={{fontSize : 20}} className="text-white"/></a></NavItem>
            <NavItem className='mx-2 '><a href="#"><FaWhatsapp style={{fontSize : 20}} className="text-white"/></a></NavItem>
        </Nav>
        <Nav className='d-flex text-skin-white pt-3 mt-3 mt-md-0'>
        <NavItem className='px-2 text-white'>Term of Use</NavItem>
           <NavItem className='px-2 text-white'>Privacy Policy</NavItem>
        </Nav>
     </div>
     </div>
    </div>
  )
}

export default Footer