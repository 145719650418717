import Cookies from "universal-cookie";
export const actionTypes = {
  SET_TOKEN: "SET_TOKEN",
  SET_LOGIN: "SET_LOGIN",
  GET_USER: "GET_USER",
  LOADING_TOGGLE_ACTION: "LOADING_TOGGLE_ACTION",
  SET_USER_NULL: "SET_USER_NULL",
  GET_FORGOT_PASSWORD: "GET_FORGOT_PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
};
const cookies = new Cookies();
const loginData = JSON.parse(localStorage.getItem("user"));
const INITIAL_STATE = {
  token: [],
  user: [loginData],
  Preloader: false,
  forgotpassword: [],
};

const LoginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // -----------------------------------set token------------------------------------
    case actionTypes.SET_TOKEN:
      cookies.set("token", action.token.Data.AccessToken);

      return {
        ...state,
        token: action.token,
        Preloader: action.Preloader,
      };

    // -----------------------------------set User------------------------------------
    case actionTypes.SET_LOGIN:
      localStorage.setItem("user", JSON.stringify(action?.user));
      return {
        ...state,
        user: action?.user,
        Preloader: action.Preloader,
      };
    case actionTypes.SET_USER_NULL:
      return {
        ...state,
        user: action.user,
      };
    case actionTypes.GET_FORGOT_PASSWORD:
      return {
        ...state,
        forgotpassword: action.forgotpassword,
        Preloader: action.Preloader,
      };
    case actionTypes.RESET_PASSWORD:
      return {
        ...state,
        forgotpassword: INITIAL_STATE.forgotpassword,
      };
    case actionTypes.LOADING_TOGGLE_ACTION:
      return {
        ...state,
        Preloader: action.Preloader,
      };
    default:
      return state;
  }
};

export default LoginReducer;
