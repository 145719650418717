export const actionTypes = {
  GET_PATIENT_PROFILE: "GET_PATIENT_PROFILE",
  SET_PRE_LOADER: "SET_PRE_LOADER",
};

const INITIAL_STATE = {
  patientprofile: [],
  PreloaderPP: false,
};

const PatientProfileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // -----------------------------------Get patient PROFILE------------------------------------
    case actionTypes.GET_PATIENT_PROFILE:
      localStorage.setItem(
        "patientprofile",
        JSON.stringify(action.patientprofile.Data)
      );
      return {
        ...state,
        patientprofile: action.patientprofile,
        PreloaderPP: action.PreloaderPP,
      };
    // -----------------------------------SET_PRE_LOADER------------------------------------
    case actionTypes.SET_PRE_LOADER:
      return {
        ...state,
        PreloaderPP: action.PreloaderPP,
      };
    default:
      return state;
  }
};

export default PatientProfileReducer;
