export const actionTypes = {
  SET_PATIENT: "SET_PATIENT",
  SET_PRE_LOADER: "SET_PRE_LOADER",
  SET_PRE_LOADER_SYMPTOM: "SET_PRE_LOADER_SYMPTOM",
  GET_MESSAGE_BY_USER_LIST: "GET_MESSAGE_BY_USER_LIST",
  GET_MESSAGE_CHATTEMPLET_LIST: "GET_MESSAGE_CHATTEMPLET_LIST",
  GET_MESSAGE_Type_LIST: "GET_MESSAGE_Type_LIST",
  GET_MESSAGE_TIME_LINE_LIST_PATIENT: "GET_MESSAGE_TIME_LINE_LIST_PATIENT",
  GET_NEW_CASERECORD_ID: "GET_NEW_CASERECORD_ID",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  RESET_DETAIL: "RESET_DETAIL",
  GET_SYMPTOM_LIST: "GET_SYMPTOM_LIST",
  GET_PROCEDURE_LIST: "GET_PROCEDURE_LIST",
  NEW_SYMPTOM_LIST: "NEW_SYMPTOM_LIST",
  GET_DIAGNOSIS_LIST: "GET_DIAGNOSIS_LIST",
  NEW_DIAGNOSIS_LIST: "NEW_DIAGNOSIS_LIST",
  NEW_RUBRIC_LIST: "NEW_RUBRIC_LIST",
  GET_ANALYSIS_REPORT_LIST: "GET_ANALYSIS_REPORT_LIST",
  GET_COMMENT: "GET_COMMENT",
  SELECTED_CONSULTATION_ID: "SELECTED_CONSULTATION_ID",
  GET_DELIVERY_ADDRESS: "GET_DELIVERY_ADDRESS",
  WORKSHEET_DETAIL: "WORKSHEET_DETAIL",
  PRESCRIPTION_DETAIL: "PRESCRIPTION_DETAIL",
  GET_CASERECORD_FILE: "GET_CASERECORD_FILE",
};
const INITIAL_STATE = {
  patientDetail: [],
  PreloaderPatient: false,
  PreloaderSymptom: false,
  MessageByUserList: [],
  MessageChatTemplateGetList: [],
  MessageTypeList: [],
  MessageTimeLineListpatient: [],
  updateprofile: [],
  Symptomlist: [],
  NewSymptomlist: [],
  Diagnosislist: [],
  NewDiagnosislist: [],
  Rubriclist: [],
  Procedurelist: [],
  Reportlist: [],
  Comment: [],
  selectedid: null,
  deliveryaddress: [],
  workdetail: [],
  PrescriptionDetailsByDelivery: [],
  caserecordFile: [],
};

const patientDetailReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // -----------------------------------patientDetail------------------------------------
    case actionTypes.SET_PATIENT:
      localStorage.setItem(
        "patientDetail",
        JSON.stringify(action.patientDetail)
      );
      return {
        ...state,
        patientDetail: action.patientDetail,
        PreloaderPatient: action.PreloaderPatient,
      };

    // -----------------------------------patientDetail------------------------------------
    case actionTypes.RESET_DETAIL:
      return {
        ...state,
        patientDetail: INITIAL_STATE.patientDetail,
      };
    case actionTypes.SELECTED_CONSULTATION_ID:
      return {
        ...state,
        selectedid: action.selectedid,
      };
    case actionTypes.GET_CASERECORD_FILE:
      return {
        ...state,
        caserecordFile: action.caserecordFile,
      };
    case actionTypes.GET_DELIVERY_ADDRESS:
      return {
        ...state,
        deliveryaddress: action.deliveryaddress,
      };
    case actionTypes.WORKSHEET_DETAIL:
      return {
        ...state,
        workdetail: action.workdetail,
      };
    case actionTypes.PRESCRIPTION_DETAIL:
      return {
        ...state,
        PrescriptionDetailsByDelivery: action.PrescriptionDetailsByDelivery,
      };
    // -----------------------------------sympton list------------------------------------
    case actionTypes.GET_SYMPTOM_LIST:
      return {
        ...state,
        Symptomlist: action.Symptomlist,
      };
    // -----------------------------------Procedure list------------------------------------
    case actionTypes.GET_PROCEDURE_LIST:
      return {
        ...state,
        Procedurelist: action.Procedurelist,
      };
    // -----------------------------------New sympton list------------------------------------
    case actionTypes.NEW_SYMPTOM_LIST:
      return {
        ...state,
        NewSymptomlist: action.NewSymptomlist,
        PreloaderSymptom: action.PreloaderSymptom,
      };
    // -----------------------------------Diagnosis list------------------------------------
    case actionTypes.GET_DIAGNOSIS_LIST:
      return {
        ...state,
        Diagnosislist: action.Diagnosislist,
      };
    // -----------------------------------New Diagnosis list------------------------------------
    case actionTypes.NEW_DIAGNOSIS_LIST:
      return {
        ...state,
        NewDiagnosislist: action.NewDiagnosislist,
        PreloaderSymptom: action.PreloaderSymptom,
      };
    // -----------------------------------Rubric list------------------------------------
    case actionTypes.NEW_RUBRIC_LIST:
      return {
        ...state,
        Rubriclist: action.Rubriclist,
        PreloaderSymptom: action.PreloaderSymptom,
      };
    // -----------------------------------Report list------------------------------------
    case actionTypes.GET_ANALYSIS_REPORT_LIST:
      return {
        ...state,
        Reportlist: action.Reportlist,
        PreloaderSymptom: action.PreloaderSymptom,
      };
    // -----------------------------------comment list------------------------------------
    case actionTypes.GET_COMMENT:
      return {
        ...state,
        Comment: action.Comment,
        PreloaderSymptom: action.PreloaderSymptom,
      };
    // -----------------------------------SET_PRE_LOADER------------------------------------
    case actionTypes.SET_PRE_LOADER:
      return {
        ...state,
        PreloaderPatient: action.PreloaderPatient,
      };
    case actionTypes.SET_PRE_LOADER_SYMPTOM:
      return {
        ...state,
        PreloaderSymptom: action.PreloaderSymptom,
      };
    // -----------------------------------GET_MESSAGE_BY_USER_LIST------------------------------------
    case actionTypes.GET_MESSAGE_BY_USER_LIST:
      return {
        ...state,
        MessageByUserList: action.MessageByUserList,
        PreloaderPatient: action.PreloaderPatient,
      };
    // -----------------------------------GET_MESSAGE_BY_USER_LIST------------------------------------
    case actionTypes.GET_MESSAGE_CHATTEMPLET_LIST:
      return {
        ...state,
        MessageChatTemplateGetList: action.MessageChatTemplateGetList,
        PreloaderPatient: action.PreloaderPatient,
      };
    // -----------------------------------GET_MESSAGE_Type_LIST------------------------------------
    case actionTypes.GET_MESSAGE_Type_LIST:
      return {
        ...state,
        MessageTypeList: action.MessageTypeList,
        PreloaderPatient: action.PreloaderPatient,
      };
    // -----------------------------------GET_MESSAGE_TIME_LINE_LIST_PATIENT------------------------------------
    case actionTypes.GET_MESSAGE_TIME_LINE_LIST_PATIENT:
      return {
        ...state,
        MessageTimeLineListpatient: action.MessageTimeLineListpatient,
        PreloaderPatient: action.PreloaderPatient,
      };
    case actionTypes.UPDATE_PROFILE:
      return {
        ...state,
        updateprofile: action.updateprofile,
        PreloaderPatient: action.PreloaderPatient,
      };

    default:
      return state;
  }
};

export default patientDetailReducer;
