import React , { useState , useEffect } from "react";
import {Card,Col,Row,CardTitle,CardText,Button} from 'reactstrap'
import '../css/Layout.css'
import { connect } from "react-redux";

const Doctorvideo = ({PreviewData}) => {
    const [DoctorData, setDoctorData] = useState();
  
    useEffect(() => {
      setDoctorData(PreviewData && PreviewData?.Data)
      }, [PreviewData])
  return (
    <>
   {DoctorData &&  <>
      <div className='h-auto mt-5 newcolor textfamily'>
        <div className='background'>
      <div className='text-center pt-1'>
           <h2 className='my-5 text theme-text-color'>Happy patients with <strong>{DoctorData?.Table[0]?.name}</strong>. Success stories say that.</h2>
      </div>
    <Row className='container mx-auto mt-2 pb-5'>
    <Col lg="6">
      <Card className='py-5 cardheight  py-lg-3 py-xl-5 mt-4 mt-lg-0'>
        <CardTitle tag="h5" className='px-sm-5 px-3 color'>
       {DoctorData?.Table6[0].TextTestimonialHeading}
        </CardTitle>
        <CardText className='px-sm-5 px-3 pt-lg-2'>
        {DoctorData?.Table6[0].TextTestimonialDesc}
        </CardText>
      </Card>
    </Col>
    <Col lg="6">
      <Card className='py-5 cardheight  py-lg-3 py-xl-5 mt-4 mt-lg-0'>
        <CardTitle tag="h5" className='px-sm-5 px-3 color'>
        Check out what satisfied patients have to say about our Homeopathy Treatment!
        </CardTitle>
        
           <iframe className="w-75 my-3 mx-auto"
        style={{  width: '100%', height: '200px' }}
        src={DoctorData?.Table6[0].VideoTestimonial}
        title="Prashant Chauhan’s problem of Allergic Rhinitis wa ..."
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen="">
      </iframe>

      </Card>
    </Col>
  </Row>
  </div>
  </div>
  <div className='my-5 newcolor textfamily text-center'>
        <a href="#" className=" btn back px-4 py-3 text-white">Start Treatment</a>
    </div>
    </>}
  </>
  )
}


const mapStateToProps = (state) => {
  return {
    PreviewData: state.WebConfig.PreviewData.Data,
  };
};
export default connect(mapStateToProps)(Doctorvideo);