export const actionTypes = {
  SET_HOMEPAGE_DETAIL: "SET_HOMEPAGE_DETAIL",
  SET_PROFILE_IMAGE: "SET_PROFILE_IMAGE",
  SET_LOGO_IMAGE: " SET_LOGO_IMAGE",
  GET_DOCTOR_DETAIL: "GET_DOCTOR_DETAIL",
  GET_AFFILIATE_PROFILE_SPECIALITIES_BY_USER_ID:
    "GET_AFFILIATE_PROFILE_SPECIALITIES_BY_USER_ID",
  GET_AFFILIATE_DOCTOR_QUALIFICATION_LIST:
    "GET_AFFILIATE_DOCTOR_QUALIFICATION_LIST",
  GET_AFFILIATE_QUALIFICATION_BY_ID: "GET_AFFILIATE_QUALIFICATION_BY_ID",
  GET_AFFILIATE_HEALTH_PLAN_ID: "GET_AFFILIATE_HEALTH_PLAN_ID",
  GET_THEME_LIST: "GET_THEME_LIST",
  GET_THEME_URL: "GET_THEME_URL",
  GET_SOCIAL_ICONS_LIST: "GET_SOCIAL_ICONS_LIST",
  GET_GELLERY_EVENTLIST: "GET_GELLERY_EVENTLIST",
  RESET_GELLERY_EVENTLIST: "RESET_GELLERY_EVENTLIST",
  GET_GELLERY_LIST: "GET_GELLERY_LIST",
  GET_REVENUE_SHARE: "GET_REVENUE_SHARE",
  GET_BILLING_PLAN: "GET_BILLING_PLAN",
  GET_ACCOUNT_STATUS: "GET_ACCOUNT_STATUS",
  GET_TESTIMONIAL_TEXT: "GET_TESTIMONIAL_TEXT",
  GET_TESTIMONIAL_VIDEO: "GET_TESTIMONIAL_VIDEO",
  GET_DOCUMENT_LIST: "GET_DOCUMENT_LIST",
  GET_WEBSITE_DOMAIN: "GET_WEBSITE_DOMAIN",
  EDIT_TESTIMONIAL_DATA: "EDIT_TESTIMONIAL_DATA",
  EDIT_HEALTH_PLAN: "EDIT_HEALTH_PLAN",
  GET_PREVIEW_PAGE_DATA: "GET_PREVIEW_PAGE_DATA",
  SET_PRE_LOADER_WEB_CONFIG: "SET_PRE_LOADER_WEB_CONFIG",
  DOMAIN_CHECK_AVAILABILITY: "DOMAIN_CHECK_AVAILABILITY",
  DOMAIN_REGISTRATION: "DOMAIN_REGISTRATION",
  GET_QUALIFICATION_SUGGESTION_LIST: "GET_QUALIFICATION_SUGGESTION_LIST",
};

const initialState = {
  doctorDetail: [],
  homepageDetail: [],
  ProfileSpecialitiesbyuser: [],
  DoctorQualificationList: [],
  QualificationByid: [],
  AffiliateHealthPlan: [],
  themelist: [],
  themeUrllist: [],
  socialIconList: [],
  gelleryEventList: [],
  gelleryList: [],
  revenueShare: [],
  billingDetail: [],
  accountStatus: [],
  testimonialText: [],
  testimonialVideo: [],
  documentList: [],
  webDomain: [],
  editTestData: [],
  editHealthPlan: [],
  profileImgLink: [],
  logoImgLink: [],
  PreviewData: [],
  DomainCheck: [],
  DomainRegistration: [],
  QualificationSuggestionsList: [],
  webConfigPreloader: false,
};

const WebConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_DOCTOR_DETAIL:
      return {
        ...state,
        doctorDetail: action.doctorDetail,
        webConfigPreloader: action.webConfigPreloader,
      };
    /*-----------------SET_HOMEPAGE_DETAIL---------------- */
    case actionTypes.SET_HOMEPAGE_DETAIL:
      return {
        ...state,
        homepageDetail: action.homepageDetail,
        webConfigPreloader: action.webConfigPreloader,
      };
    /*----------------- SET_PROFILE_IMAGE---------------- */
    case actionTypes.SET_PROFILE_IMAGE:
      return {
        ...state,
        profileImgLink: action.profileImgLink,
        webConfigPreloader: action.webConfigPreloader,
      };
    /*-----------------SET_LOGO_IMAGE---------------- */
    case actionTypes.SET_LOGO_IMAGE:
      return {
        ...state,
        logoImgLink: action.logoImgLink,
        webConfigPreloader: action.webConfigPreloader,
      };
    /*-----------------GET_AFFILIATE_PROFILE_SPECIALITIES_BY_USER_ID---------------- */
    case actionTypes.GET_AFFILIATE_PROFILE_SPECIALITIES_BY_USER_ID:
      return {
        ...state,
        ProfileSpecialitiesbyuser: action.ProfileSpecialitiesbyuser,
        webConfigPreloader: action.webConfigPreloader,
      };
    /*-----------------GET_AFFILIATE_DOCTOR_QUALIFICATION_LIST---------------- */
    case actionTypes.GET_AFFILIATE_DOCTOR_QUALIFICATION_LIST:
      return {
        ...state,
        DoctorQualificationList: action.DoctorQualificationList,
        webConfigPreloader: action.webConfigPreloader,
      };
    /*-----------------GET_QUALIFICATION_SUGGESTION_LIST---------------- */
    case actionTypes.GET_QUALIFICATION_SUGGESTION_LIST:
      return {
        ...state,
        QualificationSuggestionsList: action.QualificationSuggestionsList,
        webConfigPreloader: action.webConfigPreloader,
      };
    /*-----------------GET_AFFILIATE_QUALIFICATION_BY_ID---------------- */
    case actionTypes.GET_AFFILIATE_QUALIFICATION_BY_ID:
      return {
        ...state,
        QualificationByid: action.QualificationByid,
        webConfigPreloader: action.webConfigPreloader,
      };
    /*-----------------AffiliateHEALTHPlan---------------- */
    case actionTypes.GET_AFFILIATE_HEALTH_PLAN_ID:
      return {
        ...state,
        AffiliateHealthPlan: action.AffiliateHealthPlan,
        webConfigPreloader: action.webConfigPreloader,
      };
    /*-----------------themelist---------------- */
    case actionTypes.GET_THEME_LIST:
      return {
        ...state,
        themelist: action.themelist,
        webConfigPreloader: action.webConfigPreloader,
      };
    /*-----------------themelist---------------- */
    case actionTypes.GET_THEME_URL:
      return {
        ...state,
        themeUrllist: action.themeUrllist,
        webConfigPreloader: action.webConfigPreloader,
      };
    /*-----------------themelist---------------- */
    case actionTypes.GET_SOCIAL_ICONS_LIST:
      return {
        ...state,
        socialIconList: action.socialIconList,
        webConfigPreloader: action.webConfigPreloader,
      };
    /*-----------------gellery event list---------------- */
    case actionTypes.GET_GELLERY_EVENTLIST:
      return {
        ...state,
        gelleryEventList: action.gelleryEventList,
        webConfigPreloader: action.webConfigPreloader,
      };

    case actionTypes.RESET_GELLERY_EVENTLIST:
      return {
        ...state,
        gelleryEventList: initialState.gelleryEventList,
      };
    /*-----------------gellery list---------------- */
    case actionTypes.GET_GELLERY_LIST:
      return {
        ...state,
        gelleryList: action.gelleryList,
        webConfigPreloader: action.webConfigPreloader,
      };
    /*-----------------revenue Share---------------- */
    case actionTypes.GET_REVENUE_SHARE:
      return {
        ...state,
        revenueShare: action.revenueShare,
        webConfigPreloader: action.webConfigPreloader,
      };
    /*-----------------billing plan---------------- */
    case actionTypes.GET_BILLING_PLAN:
      return {
        ...state,
        billingDetail: action.billingDetail,
        webConfigPreloader: action.webConfigPreloader,
      };
    /*-----------------Account status---------------- */
    case actionTypes.GET_ACCOUNT_STATUS:
      return {
        ...state,
        accountStatus: action.accountStatus,
        webConfigPreloader: action.webConfigPreloader,
      };
    /*-----------------Testimonial TEXT---------------- */
    case actionTypes.GET_TESTIMONIAL_TEXT:
      return {
        ...state,
        testimonialText: action.testimonialText,
        webConfigPreloader: action.webConfigPreloader,
      };
    /*-----------------Testimonial TEXT---------------- */
    case actionTypes.GET_TESTIMONIAL_VIDEO:
      return {
        ...state,
        testimonialVideo: action.testimonialVideo,
        webConfigPreloader: action.webConfigPreloader,
      };
    /*-----------------editTestData---------------- */
    case actionTypes.EDIT_TESTIMONIAL_DATA:
      return {
        ...state,
        editTestData: action.editTestData,
        webConfigPreloader: action.webConfigPreloader,
      };
    /*-----------------WEBSITE DOMAIN---------------- */
    case actionTypes.GET_WEBSITE_DOMAIN:
      return {
        ...state,
        webDomain: action.webDomain,
        webConfigPreloader: action.webConfigPreloader,
      };
    /*-----------------DOMAIN_CHECK_AVAILABILITY---------------- */
    case actionTypes.DOMAIN_CHECK_AVAILABILITY:
      return {
        ...state,
        DomainCheck: action.DomainCheck,
        webConfigPreloader: action.webConfigPreloader,
      };
    /*-----------------DOMAIN_REGISTRATION---------------- */
    case actionTypes.DOMAIN_REGISTRATION:
      return {
        ...state,
        DomainRegistration: action.DomainRegistration,
        webConfigPreloader: action.webConfigPreloader,
      };
    /*-----------------document list---------------- */
    case actionTypes.GET_DOCUMENT_LIST:
      return {
        ...state,
        documentList: action.documentList,
        webConfigPreloader: action.webConfigPreloader,
      };
      return {
        ...state,
        docCheckListResume: action.docCheckListResume,
        webConfigPreloader: action.webConfigPreloader,
      };
    /*-----------------editHealthPlan---------------- */
    case actionTypes.EDIT_HEALTH_PLAN:
      return {
        ...state,
        editHealthPlan: action.editHealthPlan,
        webConfigPreloader: action.webConfigPreloader,
      };
    /*-----------------PreviewData---------------- */
    case actionTypes.GET_PREVIEW_PAGE_DATA:
      return {
        ...state,
        PreviewData: action.PreviewData,
        webConfigPreloader: action.webConfigPreloader,
      };
    /*-----------------webConfigPreloader---------------- */
    case actionTypes.SET_PRE_LOADER_WEB_CONFIG:
      return {
        ...state,
        webConfigPreloader: action.webConfigPreloader,
      };
    default:
      return state;
  }
};

export default WebConfigReducer;
