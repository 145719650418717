import React , {useState , useEffect } from 'react'
import { connect } from 'react-redux';
const Payumoney = ({PaymentPlanConfirm}) =>  {
const data = PaymentPlanConfirm 
//    data.push(PaymentPlanConfirm)
  //  console.log(data)
  return (
    <div className='w-100' style={{height: "100vh"}}>
       <iframe srcdoc={data} height="100%" width="100%"> 
    </iframe>
    </div>
   
  )
}
const mapStateToProps = (state) => {
    return {
        PaymentPlanConfirm :  state.myhealthPlan.PaymentPlanConfirm,
      PreloaderHP: state.myhealthPlan.PreloaderHP,
    };
  };
  export default connect(mapStateToProps)(Payumoney);