import React , { useState , useEffect } from "react";
import { FaGraduationCap } from "react-icons/fa";
import { AiFillStar } from "react-icons/ai";
import { CardGroup as div,Button ,Row,Col} from "reactstrap";
import '../css/Layout.css'
import { connect } from "react-redux";
const DoctorInfo = ({PreviewData}) => {
  const [DoctorData, setDoctorData] = useState();
  const [DoctorQualification, setDoctorQualification] = useState();
  const [specialist, setSpecialist] = useState();
  useEffect(() => {
    setDoctorData(PreviewData && PreviewData?.Table)
    setDoctorQualification(PreviewData && PreviewData?.Table2)
    setSpecialist(PreviewData && PreviewData?.Table6)
    }, [PreviewData])
  return (<>
  {DoctorData && 
  <div className="container mx-auto pt-2 font-Roboto newcolor textfamily w-75">
      <Row>
      {/* <div className="d-flex flex-wrap justify-content-center justify-content-lg-center"> */}
        
        <Col md='6' xl='4' lg='4' xs="12" className="col background d-flex my-2">
        <div className=" mx-auto img-box ">
          <img src={DoctorData[0].Photo} alt="" className="doc-img" />
        </div>
        </Col>
        <Col md='6' xl='4' lg='4' xs="12" className=" my-2">
        <div className=" mx-2 pb-3 px-3 mt-4 mt-md-0">
          <div>
            {/* <h1 className="mb-2 color">{DoctorData[0].DoctorName}</h1> */}
           <p className="py-2 mb-2 fs-5 doc-title" contentEditable='false' dangerouslySetInnerHTML={{ __html:  DoctorData[0].Specialisation.substring(0, 400) + '...' }}></p>
           {/* <p className="py-2 mb-2 fs-5 doc-title" contentEditable='false' dangerouslySetInnerHTML={{ __html:DoctorData[0] && DoctorData[0]?.Profile.substring(0, 400) + '...' }}></p> */}
            {/* <p >
              Dr. Jawahar J. Shah M.D. (Hom.) Serving Humanity passionately by
              integrating technology and knowledge to deliver healthy results A
              successful clinician and a dedicated Homeopath… Five Generations
              following Homoeopathy Running a busy practice since last 40 years
              Specializes in treating cases of...
            </p> */}
          </div>
          <div>
            <a href="#"  className="btn text-white px-4 py-2 back">
              View Profile
            </a>
          </div>
        </div>
        </Col>
        <Col md='6' xl='4' lg='4' xs="12" className="background  my-2 mx-auto">
        <div
          className=" mx-1  my-auto py-5 px-5 px-md-3 px-xl-5"
        >
          <div className="row ">
          <div className='col-2'>
            <FaGraduationCap style={{fontSize : 40}} className='theme-icon-color '/>
            </div>
            <div className="mx-2 col-8">
              <h6 className="theme-text-color"><strong>QUALIFICATION</strong></h6>
              <p className="theme-text-color font-bold">{DoctorQualification && DoctorQualification[0].Qualification}</p>
            </div>
          </div>
          <div className="row mt-3">
            
            <div className='col-2'>
               <AiFillStar style={{fontSize : 40}} className='theme-icon-color'/>
            </div>
           
            <div className="mx-2 col-8">
              <h6 className="theme-text-color"><strong>SPECIALITIES</strong></h6>
              <p className="theme-text-color font-bold" contentEditable='false' dangerouslySetInnerHTML={{ __html:  specialist[0].AddtionalDisease.substring(0, 100) + '...' }}></p>
             
              {/* <p className="color">
                Bronchial Asthma, Allergies, Autism, Pain reliefin Cancer cases,
                Acute Cases, Chronic...
              </p> */}
            </div>
          </div>
        </div>
        </Col>
      {/* </div> */}
      </Row>
    </div>
  }</>
    
  );
}
const mapStateToProps = (state) => {
  return {
    PreviewData: state.WebConfig.PreviewData?.Data?.Data,
  };
};
export default connect(mapStateToProps)(DoctorInfo);
