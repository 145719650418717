import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import PostsReducer from "./reducers/PostsReducer";
import thunk from "redux-thunk";
import { AuthReducer } from "./reducers/AuthReducer";
import LoginReducer from "./reducers/login-user-Reducer";
import todoReducers from "./reducers/Reducers";
import CreateContactReducer from "./reducers/Create-Contact-Reducer";
import UserProfileReducer from "./reducers/User-Profile-Reducer";
import PatientListReducer from "./reducers/Patient-List-Reducer";
import SupportReducer from "./reducers/Support-Reducer";
import PatientQueriesReducer from "./reducers/Patient-Queries-Reducer";
import EarningsReducer from "./reducers/Earnings-Reducer";
import { reducer as reduxFormReducer } from "redux-form";
import WebConfigReducer from "./reducers/WebConfigReducer";
import patientDetailReducer from "./reducers/Patient-detail-reducer";
import PatientProfileReducer from "./reducers/Patient-Profile-Reducer";
import CaseRecordReducer from "./reducers/CaseRecord-Reducer";
import CaseHistoryReducer from "./reducers/CaseHistory-Reducer";
import MyHealthPlanReducer from "./reducers/MyHealthPlan-Reducer";
import TransactionReducer from "./reducers/Transaction-Reducer";
import PrescriptionReducer from "./reducers/Prescription-Reducer";

const middleware = applyMiddleware(thunk);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  posts: PostsReducer,
  auth: AuthReducer,
  login: LoginReducer,
  profile: UserProfileReducer,
  todoReducers,
  form: reduxFormReducer,
  CreateContact: CreateContactReducer,
  patientlist: PatientListReducer,
  support: SupportReducer,
  PatientQueries: PatientQueriesReducer,
  Earnings: EarningsReducer,
  WebConfig: WebConfigReducer,
  patientDetail: patientDetailReducer,
  patientprofile: PatientProfileReducer,
  caserecord: CaseRecordReducer,
  casehistory: CaseHistoryReducer,
  myhealthPlan: MyHealthPlanReducer,
  transaction: TransactionReducer,
  Prescription: PrescriptionReducer,
});

export const store = createStore(reducers, composeEnhancers(middleware));
