import React , { useState , useEffect } from "react";
import {Button} from "reactstrap"
import banner from "../images/demo_img/Banner.png"
import '../css/Layout.css'
import { connect } from "react-redux";

const DoctorBanner = ({PreviewData}) => {
  const [DoctorData, setDoctorData] = useState();
  useEffect(() => {
    setDoctorData(PreviewData && PreviewData)
    }, [PreviewData])
  
  return (<>
  {DoctorData &&
    <div className='position-relative text-center newcolor textfamily mb-5'>
        {/* <div className='bg-skin-light w-full h-auto py-60 xl:mt-32 relative mt-28'></div> */}
       <div className='margin newcolor'><img src={banner} className='w-100 position-relative banner' alt='new'></img></div>
        <div className='position-absolute top-50 start-50 translate-middle mt-4 w-100 px-4 px-sm-0 mx-2'>
            <h1 className='text-white mb-4'><strong>{DoctorData?.Table6[0]?.mainheadertext}</strong></h1>
            <h1 className='text-white mb-4'>HomeoPathy Consultation With <strong className='color'>{DoctorData?.Table[0]?.Name}</strong></h1>
            <h4 className='text-white'>{DoctorData?.Table6[0]?.usp1} | {DoctorData?.Table6[0]?.usp2} | {DoctorData?.Table6[0]?.usp3}</h4>
            <div className="my-5">
                <Button className="back px-4 py-2">Start Treatment</Button>
              </div>
        </div>
    </div>
  }</>
    
  )
}

const mapStateToProps = (state) => {
  return {
    PreviewData: state.WebConfig.PreviewData.Data?.Data,
  };
};
export default connect(mapStateToProps)(DoctorBanner);