export const isAuthenticated = (state) => {

    const Data = JSON.parse(localStorage.getItem('user'));
    if(Data){
      if (Data && Data.Data?.Table) return true;
        return false   
    }else{
        if(window.location.href.indexOf("resetpassword") == 0){
            window.location.assign("/resetpassword");
        }else if(window.location.href.indexOf("login") <= -1 && window.location.href.indexOf("resetpassword") <= -1){
            window.location.assign("/login");
        }
    }
    
    
// if (state.login.user.Data) return true;
    // return false;
    
};
