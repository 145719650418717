import React , { useState , useEffect } from "react";
import { ImUserPlus } from "react-icons/im"
import { FaUserMd } from "react-icons/fa"
import { IoBagAddSharp } from "react-icons/io5"
import { FaNotesMedical } from "react-icons/fa"
import { CardGroup, Col, Row } from "reactstrap"
import '../css/Layout.css'
import { connect } from "react-redux";
const DoctorCard = ({PreviewData}) => {

  const [DoctorData, setDoctorData] = useState();

  useEffect(() => {
    setDoctorData(PreviewData && PreviewData?.Table)
    }, [PreviewData])
  return (<>
  {DoctorData &&
     <div className='w-75 mt-5 mx-auto newcolor textfamily'>
      <div className='text-center'>
        <h2 className='text'>Your Journey to Better Health with <strong className='color'>{DoctorData[0].name}</strong></h2>
        <h5 className='mt-4 mb-5'>Simple Steps from the Comfort of your Home</h5>
      </div>
      <CardGroup>
        <Row className='flex-xl-nowrap  mx-auto justify-content-between'>
          <div className='p-0 mx-auto theme-card-bg highlight cal-width '>
            <div className=' p-4 theme-text-color '>
              <ImUserPlus className='text-5xl theme-icon-color mt-3 newicons' />
              <div className='text-center '>
                <h6 className="mt-4 mb-2 theme-text-color">
                  <strong>SIGN-UP</strong>
                </h6>
                <p className='pb-5'>Register and select an affordable plan</p>
              </div>
            </div>

          </div>
          <div className=' mx-auto theme-card-bg highlight cal-width '>
            <div className=' p-4 theme-text-color '>
                <FaUserMd className='text-5xl theme-icon-color mt-3 newicons' />
                <div className='text-center'>
                  <h6 className="mt-4 mb-2 theme-text-color">
                    <strong>CONSULTATION</strong>
                  </h6>
                  <p className='pb-4'>Our team will consult you over the call, video and analyse your complaints</p>
                </div>
              </div>
          
          </div> 
          <div className='mx-auto theme-card-bg highlight cal-width'>
            <div className=' p-4 theme-text-color'>
                <IoBagAddSharp className='text-5xl theme-icon-color mt-3 newicons' />
                <div className='text-center'>
                  <h6 className="mt-4 mb-2 theme-text-color">
                    <strong>GET MEDICINES</strong>
                  </h6>
                  <p className='pb-4'>You get your prescription or Quality Medicines are delivered at your doorstep</p>
                </div>
              </div>
       
          </div>
          <div className='mx-auto theme-card-bg highlight cal-width'>
            <div className=' p-4 theme-text-color'>
                <FaNotesMedical className='theme-icon-color mt-3 newicons' />
                <div className='text-center'>
                  <h6 className="mt-4 mb-2 theme-text-color">
                    <strong>REGULAR FOLLOW-UPS</strong>
                  </h6>
                  <p className='pb-4'>Timely & Proactive follow-ups to your health</p>
                </div>
              </div>
      
          </div>
       
        </Row>
      </CardGroup>
    </div>
}</>
   
  )
}

const mapStateToProps = (state) => {
  return {
    PreviewData: state.WebConfig.PreviewData?.Data?.Data,
  };
};
export default connect(mapStateToProps)(DoctorCard);